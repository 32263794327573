import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isDateInRange, requiredLinksIdentifier, requiredLinksIdentifierOwnPaths } from '@app/helpers/utils';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { FacilityAdmission, QuickLink, TherapyAdmission, TherapyDiscipline } from '@app/interfaces';
import { GOAL_CONSTANTS, PATIENT_DEMOGRAPHICS } from '@app/interfaces/documentation';
import { CptCodesService } from '@app/services';
import { PatientState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-quick-links',
    templateUrl: './quick-links.component.html',
    styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit, OnChanges, OnDestroy {
    @Input() scrolledDiv;
    @Input() links: QuickLink[];
    @Input() selectedLink: QuickLink;
    @Input() storeRef: string;
    @Input() requiredValidation = false;
    @Input() path;
    @Input() save = false;
    @Output() linkChanged = new EventEmitter<QuickLink>();
    private selectedDoc;
    private onDestroy = new Subject<void>();
    public therapyDiscipline;
    public allRequiredLinks;
    public validityStatus = false;
    public admissions;
    isFirstTimeChange = true;
    highlightRequiredLinks = false;
    linksWithOwnPaths = [];
    scrolledDownLinks = [];
    isIPad = false;
    facilityAdmissionData: FacilityAdmission;
    therapyCaseId;
    public allRequiredLinksWithPath = [{ facilityAdmission: '' }, { therapyAdmission: '' }];

    constructor(
        private route: ActivatedRoute,
        private store: Store<{
            patient: PatientState;
            therapyAdmission: TherapyAdmission[];
            therapyDiscipline: TherapyDiscipline;
            facilityAdmission: FacilityAdmission;
        }>,
        private cptCodesService: CptCodesService,
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.selectedDoc = params.get('doc');
                this.therapyCaseId = params.get('therapy-case');
                if (
                    (params.get('status') && params.get('status').toLocaleLowerCase() === 'incomplete') ||
                    (params.get('status') && params.get('status').toLocaleLowerCase() === 'past due') ||
                    (params.get('status') && params.get('status').toLocaleLowerCase() === 'due') ||
                    (params.get('edit') && params.get('edit') === 'true')
                ) {
                    this.highlightRequiredLinks = true;
                }
            },
        });
        this.store.select('therapyDiscipline').subscribe((discipline) => {
            this.therapyDiscipline = discipline;
        });
    }

    ngOnInit(): void {
        const userAgent = navigator.userAgent || window['opera'];
        this.isIPad = /iPad/.test(userAgent);
        this.storeRef &&
            this.store
                .select('facilityAdmission')
                .pipe(takeUntil(this.onDestroy))
                .subscribe((data) => {
                    this.facilityAdmissionData = data;
                    if (
                        this.facilityAdmissionData.id &&
                        this.linksWithOwnPaths.filter((x) => x.ownPath[0] === 'facilityAdmission')[0]
                    ) {
                        // if (this.allRequiredLinksWithPath[0]?.facilityAdmission) {
                        this.allRequiredLinksWithPath[0].facilityAdmission = requiredLinksIdentifierOwnPaths(
                            this.facilityAdmissionData,
                            'facilityAdmission',
                            '',
                            this.linksWithOwnPaths.filter((x) => x.ownPath[0] === 'facilityAdmission')[0]
                        );
                        //}
                        //else {
                        //   this.allRequiredLinksWithPath.push({
                        //     facilityAdmission: requiredLinksIdentifierOwnPaths(
                        //       this.facilityAdmissionData,
                        //       'facilityAdmission',
                        //       '',
                        //       this.linksWithOwnPaths.filter((x) => x.ownPath[0] === 'facilityAdmission')[0]
                        //     ),
                        //     //],
                        //   });
                        // }
                    }
                });

        this.storeRef &&
            this.store
                .select('therapyAdmission')
                .pipe(takeUntil(this.onDestroy))
                .subscribe((data) => {
                    this.admissions = data;
                    this.checkValidity();
                });
        this.selectedLink = this.links[0];
    }
    checkValidity() {
        this.admissions?.length &&
            (this.allRequiredLinks = requiredLinksIdentifier(
                this.filterTherapyAdmissionByParamId(),
                this.therapyCaseId,
                this.path
            ));
        if (Array.isArray(this.allRequiredLinks)) {
            this.allRequiredLinks = this.allRequiredLinks.find((doc) => doc.id == this.selectedDoc);
        }
        if (
            this.admissions?.length > 0 &&
            this.linksWithOwnPaths.filter((x) => x.ownPath[0] === 'therapyAdmission')[0]
        ) {
            this.allRequiredLinksWithPath[1].therapyAdmission = requiredLinksIdentifierOwnPaths(
                this.admissions,
                'therapyAdmission',
                this.therapyDiscipline ? this.therapyDiscipline : 'PT',
                this.linksWithOwnPaths.filter((x) => x.ownPath[0] === 'therapyAdmission')[0]
            );
        }
    }
    ngOnChanges(changes) {
        if (
            this.selectedLink &&
            !this.isFirstTimeChange &&
            changes.scrolledDiv &&
            changes.scrolledDiv.previousValue &&
            !this.scrolledDownLinks.includes(this.selectedLink.label)
        ) {
            this.scrolledDownLinks.push(changes.scrolledDiv.previousValue);
        }
        if (changes.scrolledDiv) {
            this.checkValidity();
        }
        if (this.links) {
            this.linksWithOwnPaths = this.links.filter((x: any) => x.ownPath);
            this.links.forEach((link, index) => {
                if (link.label == this.scrolledDiv || link.label == this.scrolledDiv?.label) {
                    this.selectedLink = link;
                } else if (index === 0) {
                    this.selectedLink = link;
                }
            });
        }
        if (changes.path) {
            this.admissions?.length &&
                (this.allRequiredLinks = requiredLinksIdentifier(
                    this.filterTherapyAdmissionByParamId(),
                    this.therapyCaseId,
                    this.path
                ));
            if (Array.isArray(this.allRequiredLinks)) {
                this.allRequiredLinks = this.allRequiredLinks.find((doc) => doc.id == this.selectedDoc);
            }
        }
        if (this.isFirstTimeChange) {
            this.isFirstTimeChange = false;
        }
    }

    jumpLink(link: QuickLink): void {
        this.selectedLink = link;
        this.linkChanged.emit(link);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scroll(el: any) {
        // el.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    public validityTest(link) {

        const scrolledDivIndex = this.selectedLink
            ? this.links.findIndex((x) => x.label === this.selectedLink.label)
            : -1;

        if (link.label === PATIENT_DEMOGRAPHICS.MEDICAL_DIAGNOSIS) {
            if (link.isPrimaryMarked !== undefined) {
                return !link.isPrimaryMarked;
            }

            const isPrimaryMarked = link.data
                ? link.data.some((code) => code.isPrimary)
                : this.allRequiredLinks?.medicalDiagnosis?.icd10Codes?.some((code) => code.isPrimary);
            // if (this.allRequiredLinks?.medicalDiagnosis?.icd10Codes.length) {
            if (!isPrimaryMarked) {
                return true;
            } else {
                return false;
            }
            // } else {
            //     return false;
            // }
        }
        if (link.label === PATIENT_DEMOGRAPHICS.TREATMENT_DIAGNOSIS && this.allRequiredLinks) {
            const isOnsetDate = this.allRequiredLinks?.treatmentDiagnosis.every((x) => x.onsetDate);
            if (!isOnsetDate) return true;
        }

        if (link.label === PATIENT_DEMOGRAPHICS.PATIENT_HISTORY) {
            const isOnsetDate = this.allRequiredLinks?.patientHistory?.treatmentDiagnosis.every((x) => x.onsetDate);
            const currentTherapyCase = this.admissions.find(ad => ad._id === this.therapyCaseId);
            // onset Date not filled and documents isn't signed yet then show error, if document signed then it means it is
            // old records, because onset Date wasn't mandatory before it was done recently in Nov 2024
            if (!isOnsetDate && !currentTherapyCase?.documentation?.evaluation?.signed?.signDate) return true;
        }

        if (
            this.allRequiredLinks &&
            link?.required &&
            ((!this.isFirstTimeChange && !this.selectedLink) ||
                (scrolledDivIndex > -1 &&
                    this.scrolledDownLinks.length > 0 &&
                    this.scrolledDownLinks.includes(link.label) &&
                    !this.isFirstTimeChange) ||
                this.highlightRequiredLinks)
        ) {
            if (link?.ownPath) {
                const admission = link.ownPath[0];
                const index = this.allRequiredLinksWithPath.findIndex((obj) =>
                    Object.prototype.hasOwnProperty.call(obj, admission)
                );
                if (this.allRequiredLinksWithPath[index][admission] !== '') {
                    const admissionData = this.allRequiredLinksWithPath[index][admission];
                    if (link.storeValName === 'icd10Codes') {
                        if (!admissionData.icd10Codes?.some((x) => x.isPrimary)) return true;
                        else return false;
                    } else if (
                        admissionData &&
                        admissionData[link.storeValName] &&
                        admissionData[link.storeValName].length > 0
                    ) {
                        return true;
                    }
                }
            }

            if (link.storeValName === 'plan' && this.allRequiredLinks[link.storeValName]?.goals?.length) {
                const notInCertPeriod = this.allRequiredLinks[link.storeValName]?.goals?.some((goal: any) => {
                    if (
                        goal.status.description === GOAL_CONSTANTS.ACHIEVED ||
                        goal.status.description === GOAL_CONSTANTS.DISCONTINUED
                    ) {
                        return true;
                    }

                    let isValid = isDateInRange(
                        goal.updatedTargetDate || goal.targetDate,
                        goal.minTargetDate,
                        goal.maxTargetDate
                    );
                    if (!isValid) return true;

                    return goal?.stgs?.some((stg: any) => {
                        if (
                            stg.status.description === GOAL_CONSTANTS.ACHIEVED ||
                            stg.status.description === GOAL_CONSTANTS.DISCONTINUED
                        ) {
                            return true;
                        }

                        isValid = isDateInRange(
                            stg.updatedTargetDate || stg.targetDate,
                            goal.minTargetDate,
                            goal.maxTargetDate
                        );
                        if (!isValid) return true;
                    });
                });

                if (notInCertPeriod) return true;
            }

            if (
                (link.storeValName === 'goals' || link.storeValName === 'icd10Codes') &&
                this.allRequiredLinks[link.storeValName] &&
                this.allRequiredLinks[link.storeValName]?.length > 0
            ) {
                console.log();
            } else if (
                this.allRequiredLinks[link.storeValName] &&
                this.allRequiredLinks[link.storeValName]?.length > 0
            ) {
                if (link?.child?.length > 0) {
                    return !this.childLinkValidation(link?.child);
                }
                if (link?.storeValName === 'skilledServices') {
                    return !(this.allRequiredLinks[link?.storeValName]?.length > 1)
                }
                return false;
            }
            return true;
        }
        return false;
    }

    public validClass(link) {
        if (this.requiredValidation) {
            if (link.label === 'Medical Diagnosis') {
                if (link.isPrimaryMarked !== undefined) {
                    return link.isPrimaryMarked;
                }

                const isPrimaryMarked = this.allRequiredLinks?.medicalDiagnosis?.icd10Codes?.some(
                    (code) => code.isPrimary
                );
                // if (this.allRequiredLinks?.medicalDiagnosis?.icd10Codes.length) {
                if (!isPrimaryMarked) {
                    return false;
                } else {
                    return true;
                }
                // } else {
                //     return true;
                // }
            }
            if (link.label === PATIENT_DEMOGRAPHICS.TREATMENT_DIAGNOSIS) {
                const isOnsetDate = this.allRequiredLinks?.treatmentDiagnosis.every((x) => x.onsetDate);
                if (!isOnsetDate) return false;

            }
            if (link.label === PATIENT_DEMOGRAPHICS.PATIENT_HISTORY) {
                const isOnsetDate = this.allRequiredLinks?.patientHistory?.treatmentDiagnosis.every((x) => x.onsetDate);
                if (!isOnsetDate) return false;
            }
            if (link?.child?.length > 0) {
                return this.childLinkValidation(link?.child, link);
            } else if (link.storeValName === 'icd10Codes') {
                if (this.allRequiredLinks?.medicalDiagnosis.icd10Codes.some((x) => x.isPrimary)) return true;
                return false;
            } else if (
                link?.storeValName &&
                link?.storeValName === 'goals' &&
                this.allRequiredLinks &&
                this.allRequiredLinks[link?.storeValName] &&
                this.allRequiredLinks[link.storeValName]?.length > 0
            ) {
                if (
                    this.allRequiredLinks[link.storeValName]?.every((g) => {
                        let subGoalErrorDoesNotExist = true;
                        if (g.stgs.length > 0) {
                            subGoalErrorDoesNotExist = g.stgs.every(
                                (stg) => stg.target && stg.targetDate && stg.goalPurpose
                            );
                        }
                        if (!subGoalErrorDoesNotExist) return false;
                        else if (
                            !g.target ||
                            !g.targetDate ||
                            !g.goalPurpose
                            // !this.targetDateWithinCert(g.targetDate, g.minTargetDate, g.maxTargetDate)
                        )
                            return false;
                        else return true;
                    })
                )
                    return true;
                else return false;
            } else if (
                link?.storeValName &&
                this.allRequiredLinks &&
                this.allRequiredLinks[link?.storeValName] &&
                this.allRequiredLinks[link.storeValName]?.length > 0
            ) {
                if(link?.storeValName === 'skilledServices'){
                    return this.allRequiredLinks[link?.storeValName]?.length > 1
                }
                return true;
            } else if (link?.ownPath) {
                const admission = link.ownPath[0];
                const index = this.allRequiredLinksWithPath.findIndex((obj) =>
                    Object.prototype.hasOwnProperty.call(obj, admission)
                );
                if (this.allRequiredLinksWithPath[index][admission] !== '') {
                    const admissionData = this.allRequiredLinksWithPath[index][admission];
                    if (
                        admissionData &&
                        admissionData[link.storeValName] &&
                        admissionData[link.storeValName].length > 0
                    ) {
                        return true;
                    }
                }
            } else return false;
        }
    }

    public childLinkValidation(child, link?) {
        let childHasValue = true;
        for (let i = 0; i < child.length; i++) {
            if (this.allRequiredLinks) {
                if (link?.storeValName === 'plan' && this.allRequiredLinks[link.storeValName]?.goals?.length) {
                    const notInCertPeriod = this.allRequiredLinks[link.storeValName]?.goals?.some((goal: any) => {
                        let validStatus = true;
                        if (
                            goal.status.description === GOAL_CONSTANTS.ACHIEVED ||
                            goal.status.description === GOAL_CONSTANTS.DISCONTINUED
                        ) {
                            validStatus = false;
                        }

                        let isValid = isDateInRange(
                            goal.updatedTargetDate || goal.targetDate,
                            goal.minTargetDate,
                            goal.maxTargetDate
                        );
                        if (!isValid && validStatus) return true;

                        return goal?.stgs?.some((stg: any) => {
                            if (
                                stg.status.description === GOAL_CONSTANTS.ACHIEVED ||
                                stg.status.description === GOAL_CONSTANTS.DISCONTINUED
                            ) {
                                return false;
                            }

                            isValid = isDateInRange(
                                stg.updatedTargetDate || stg.targetDate,
                                goal.minTargetDate,
                                goal.maxTargetDate
                            );
                            if (!isValid) return true;
                        });
                    });

                    if (notInCertPeriod) return false;
                }

                if (link?.storeValName) {
                    if (
                        (this.allRequiredLinks &&
                            this.allRequiredLinks[link?.storeValName]?.[child[i]?.storeValName]?.length == 0) ||
                        (typeof this.allRequiredLinks[link?.storeValName] === 'object' &&
                            this.allRequiredLinks[link?.storeValName] !== null &&
                            !Array.isArray(this.allRequiredLinks[link?.storeValName]) &&
                            !this.allRequiredLinks[link?.storeValName]?.[child[i]?.storeValName])
                    ) {
                        childHasValue = false;
                    } // array case with children
                    else if (Array.isArray(this.allRequiredLinks[link?.storeValName])) {
                        if (!this.allRequiredLinks[link?.storeValName].length) {
                            childHasValue = false;
                        } else {
                            if (
                                this.allRequiredLinks[link?.storeValName].find((elem) => !elem[child[i]?.storeValName])
                            ) {
                                childHasValue = false;
                            }
                        }
                    }
                } else {
                    if (
                        ((this.allRequiredLinks && this.allRequiredLinks[child[i]?.storeValName]?.length == 0) ||
                            !this.allRequiredLinks[child[i]?.storeValName]) &&
                        child[i]?.required
                    ) {
                        childHasValue = false;
                    }
                }

                // custom conditions validations
                if (child[i] && child[i].customConditions) {
                    if (childHasValue && child[i]?.customConditions?.includes('individualMinutesValid')) {
                        childHasValue = !!this.cptCodesService.individualMinutesValid(this.allRequiredLinks);
                    }

                    if (childHasValue && child[i]?.customConditions?.includes('otherTxValid')) {
                        childHasValue = !!this.cptCodesService.otherTxValid(
                            this.allRequiredLinks,
                            this.allRequiredLinks.objectiveAssessment.detailedCptCodes
                        );
                    }

                    if (childHasValue && child[i]?.customConditions?.includes('primaryDX')) {
                        childHasValue = this.allRequiredLinks[link.storeValName][child[i].storeValName].some(
                            (x) => x.isPrimary
                        );
                    }
                }

                //
                if (childHasValue && child[i]?.subChild) {
                    for (const subChildren of child[i].subChild) {
                        if (
                            this.allRequiredLinks[link.storeValName][child[i].storeValName].find(
                                (elem) => !elem[subChildren.storeValName]
                            )
                        ) {
                            childHasValue = false;
                        }
                    }
                }
            }
        }
        return childHasValue;
    }
    getIndex(name) {
        const index = this.allRequiredLinksWithPath.findIndex((obj) => Object.prototype.hasOwnProperty.call(obj, name));
        return index;
    }

    private filterTherapyAdmissionByParamId(): TherapyAdmission[] {
        const queryString = this.route.snapshot.queryParams;
        let currentTherapyAdmission;
        let filteredTherapyAdmissions = cloneDeep(this.admissions);
        if (queryString && queryString['therapy-case']) {
            currentTherapyAdmission = findTherapyCaseByParamId(filteredTherapyAdmissions, queryString['therapy-case']);
            filteredTherapyAdmissions = filteredTherapyAdmissions.filter(
                (admission) => admission._id === currentTherapyAdmission._id
            );
        }

        return filteredTherapyAdmissions;
    }
}
