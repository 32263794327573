import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AUDITORY_COMPREHENSION,
    CARDIOPULMONARY_IMPAIRMENTS,
    COGNITION,
    CONSTANTS,
    EXECUTIVE_FUNCTIONING,
    GENERAL_PROCESSING,
    MEMORY,
    MOTOR_SPEECH,
    NEURO_MUSCULO_SKELETAL_IMPAIRMENTS,
    ORAL_MOTOR_EXAMINATION,
    PAIN,
    PRAGMATICS,
    READING_COMPREHENSION,
    SENSORY,
    STANDARDIZED_COGNITIVE_ASSESSMENTS,
    SWALLOWING,
    VERBAL_EXPRESSION,
    VISION_PERCEPTION,
    VOICE_EVALUATION,
    WRITTEN_LANGUAGE,
} from '@app/interfaces/documentation';
import { DynamicFields } from '@app/interfaces';
import { PatientAssessmentService } from './patient-assessment.service';

export interface ImpairmentList {
    text: string;
    existsIn: string[];
    isCompleted: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class ImpairmentsService {
    constructor(private patientAssessmentService: PatientAssessmentService) {}

    getLeftRightShoulderFields(): DynamicFields[] {
        return [
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 60,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 180,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Abduction',
                key: 'abduction',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 180,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Abduction Note',
                    key: 'abductionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Adduction',
                key: 'adduction',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 30,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Adduction Note',
                    key: 'adductionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Internal Rotation',
                key: 'internalRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 70,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Internal Rotation Note',
                    key: 'internalRotationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'External Rotation',
                key: 'externalRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 90,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'External Rotation Note',
                    key: 'externalRotationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightElbowFields(): DynamicFields[] {
        return [
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 150,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 20,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Pronation',
                key: 'pronation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 180,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Pronation Note',
                    key: 'pronationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Supination',
                key: 'supination',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 180,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Supination Note',
                    key: 'supinationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightWristFields(): DynamicFields[] {
        return [
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 80,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 70,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Radial Deviation',
                key: 'radialDeviation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 20,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Radial Deviation Note',
                    key: 'radialDeviationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Ulnar Deviation',
                key: 'ulnarDeviation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 30,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Ulnar Deviation Note',
                    key: 'ulnarDeviationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getNeckFields(): DynamicFields[] {
        return [
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Left Lateral Flexion',
                key: 'leftLateralFlexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Left Lateral Flexion Note',
                    key: 'leftLateralFlexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Right Lateral Flexion',
                key: 'rightLateralFlexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Right Lateral Flexion Note',
                    key: 'rightLateralFlexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Left Lateral Rotation',
                key: 'leftLateralRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 60,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Left Lateral Rotation Note',
                    key: 'leftLateralRotationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Right Lateral Rotation',
                key: 'rightLateralRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 60,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Right Lateral Rotation Note',
                    key: 'rightLateralRotationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getThoracolumbarFields(): DynamicFields[] {
        return [
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 25,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 80,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Left Lateral Flexion',
                key: 'leftLateralFlexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 80,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Left Lateral Note',
                    key: 'leftLateralFlexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Right Lateral Flexion',
                key: 'rightLateralFlexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 80,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Right Lateral Flexion Note',
                    key: 'rightLateralFlexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Left Rotation',
                key: 'leftRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 35,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Left Rotation Note',
                    key: 'leftRotationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Right Rotation',
                key: 'rightRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 35,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Right Rotation Note',
                    key: 'rightRotationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightHipFields(): DynamicFields[] {
        return [
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 30,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 120,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Abduction',
                key: 'abduction',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Abduction Note',
                    key: 'abductionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Adduction',
                key: 'adduction',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 30,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Adduction Note',
                    key: 'adductionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Internal Rotation',
                key: 'internalRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Internal Rotation Note',
                    key: 'internalRotationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'External Rotation',
                key: 'externalRotation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 45,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'External Rotation Note',
                    key: 'externalRotationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightKneeFields(): DynamicFields[] {
        return [
            {
                label: 'Flexion',
                key: 'flexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 150,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Flexion Note',
                    key: 'flexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Extension',
                key: 'extension',
                controlType: 'input',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Extension Note',
                    key: 'extensionNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightAnkleFields(): DynamicFields[] {
        return [
            {
                label: 'Dorsiflexion',
                key: 'dorsiflexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 20,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Dorsiflexion Note',
                    key: 'dorsiflexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Plantarflexion',
                key: 'plantarflexion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 50,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Plantarflexion Note',
                    key: 'plantarflexionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Eversion',
                key: 'eversion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 15,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Eversion Note',
                    key: 'eversionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Inversion',
                key: 'inversion',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 35,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Inversion Note',
                    key: 'inversionNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Pronation',
                key: 'pronation',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 20,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Pronation Note',
                    key: 'pronationNote',
                    controlType: 'text-area',
                },
            },
            {
                label: 'Supination',
                key: 'supination',
                controlType: 'input',
                validationType: 'number',
                minValue: 0,
                maxValue: 20,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                note: {
                    label: 'Supination Note',
                    key: 'supinationNote',
                    controlType: 'text-area',
                },
            },
        ];
    }
    getLeftRightHandFields() {
        return {
            Thumb: [
                {
                    label: 'CMC flexion',
                    key: 'cmcFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 15,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'CMC flexion Note',
                        key: 'cmcFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'CMC extension',
                    key: 'cmcExtension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 20,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'CMC extension Note',
                        key: 'cmcExtensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'CMC abduction',
                    key: 'cmcAbduction',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 70,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'CMC abduction Note',
                        key: 'cmcAbductionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'CMP Flexion',
                    key: 'cmpFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 50,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'CMP flexion Note',
                        key: 'cmpFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'IP flexion',
                    key: 'ipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 80,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'IP flexion Note',
                        key: 'ipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    controlType: 'slider',
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                },
                {
                    label: 'Strength Note',
                    key: 'strengthNote',
                    controlType: 'text-area',
                },
            ],
            'Index Finger': [
                {
                    label: 'MCP flexion',
                    key: 'mcpFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP flexion Note',
                        key: 'mcpFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP hyperextension',
                    key: 'mcpHyperextension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP hyperextension Note',
                        key: 'mcpHyperextensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP abduction',
                    key: 'mcpAbduction',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'CMP abduction Note',
                        key: 'mcpAbductionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'PIP flexion',
                    key: 'pipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 100,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'PIP flexion Note',
                        key: 'pipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP flexion',
                    key: 'dipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP flexion Note',
                        key: 'dipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP Extension',
                    key: 'dipExtension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 10,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP Extension Note',
                        key: 'dipExtensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    controlType: 'slider',
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                },
                {
                    label: 'Strength Note',
                    key: 'strengthNote',
                    controlType: 'text-area',
                },
            ],
            'Middle Finger': [
                {
                    label: 'MCP flexion',
                    key: 'mcpFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP flexion Note',
                        key: 'mcpFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP hyperextension',
                    key: 'mcpHyperextension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP hyperextension Note',
                        key: 'mcpHyperextensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP abduction',
                    key: 'mcpAbduction',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP abduction Note',
                        key: 'mcpAbductionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'PIP flexion',
                    key: 'pipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 100,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'PIP flexion Note',
                        key: 'pipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP flexion',
                    key: 'dipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP flexion Note',
                        key: 'dipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP Extension',
                    key: 'dipExtension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 10,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP Extension Note',
                        key: 'dipExtensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    controlType: 'slider',
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                },
                {
                    label: 'Strength Note',
                    key: 'strengthNote',
                    controlType: 'text-area',
                },
            ],
            'Ring Finger': [
                {
                    label: 'MCP flexion',
                    key: 'mcpFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP flexion Note',
                        key: 'mcpFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP hyperextension',
                    key: 'mcpHyperextension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP hyperextension Note',
                        key: 'mcpHyperextensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP abduction',
                    key: 'mcpAbduction',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP abduction Note',
                        key: 'mcpAbductionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'PIP flexion',
                    key: 'pipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 100,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'PIP flexion Note',
                        key: 'pipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP flexion',
                    key: 'dipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP flexion Note',
                        key: 'dipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP Extension',
                    key: 'dipExtension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 10,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP Extension Note',
                        key: 'dipExtensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    controlType: 'slider',
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                },
                {
                    label: 'Strength Note',
                    key: 'strengthNote',
                    controlType: 'text-area',
                },
            ],
            'Little Finger': [
                {
                    label: 'MCP flexion',
                    key: 'mcpFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP flexion Note',
                        key: 'mcpFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP hyperextension',
                    key: 'mcpHyperextension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP hyperextension Note',
                        key: 'mcpHyperextensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'MCP abduction',
                    key: 'mcpAbduction',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 45,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'MCP abduction Note',
                        key: 'mcpAbductionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'PIP flexion',
                    key: 'pipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 100,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'PIP flexion Note',
                        key: 'pipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP flexion',
                    key: 'dipFlexion',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 90,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP flexion Note',
                        key: 'dipFlexionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'DIP Extension',
                    key: 'dipExtension',
                    controlType: 'input',
                    validationType: 'number',
                    minValue: 0,
                    maxValue: 10,
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                    note: {
                        label: 'DIP extension Note',
                        key: 'dipExtensionNote',
                        controlType: 'text-area',
                    },
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    controlType: 'slider',
                    canBeAGoal: true,
                    scaleInterpretation: CONSTANTS.MORE_IS_BEST,
                },
                {
                    label: 'Strength Note',
                    key: 'strengthNote',
                    controlType: 'text-area',
                },
            ],
        };
    }
    getCardiopulmonaryImpairmentsList() {
        return [
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.VITAL_SIGNS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.BREATH_SUPPORT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.BORG_SCALE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.PULMONARY_STATUS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.BREATHING_PATTERN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.LUNGS_SOUNDS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: CARDIOPULMONARY_IMPAIRMENTS.LE_EDEMA,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
        ];
    }
    getCardiopulmonaryVitalSignsFields() {
        return [
            {
                label: 'Respiratory Rate',
                key: 'respiratoryRate',
                controlType: 'input',
                validationType: 'number',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Temperature',
                key: 'temperature',
                controlType: 'input',
                validationType: 'number',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Pulse Rate',
                key: 'pulseRate',
                controlType: 'input',
                validationType: 'number',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Blood Pressure',
                key: 'bloodPressure',
                controlType: 'input',
                validationType: 'text',
                mask: '00[0]/00[0]',
                placeholder: '120/80',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'O2 Saturation',
                key: 'o2Saturation',
                controlType: 'input',
                validationType: 'number',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Notes/Comments',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryBreathSupportFields() {
        return [
            {
                // label: 'Breath Support',
                key: 'breathSupport',
                controlType: 'radio',
                options: ['CPAP', 'BiPAP', 'Nasal Cannula', 'Face Mask', 'Ventilator', 'Tracheostomy'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryBorgScaleFields() {
        return [
            {
                // label: 'BORG Scale',
                key: 'borgScale',
                controlType: 'radio',
                options: [
                    '0.5 - Just noticeable',
                    '1 - Very light',
                    '2 - Light',
                    '3 - Moderate',
                    '4 - Somewhat heavy',
                    '5-6 - Heavy',
                    '7-9 - Very heavy',
                    '10 - Very very heavy',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryStatusFields() {
        return [
            {
                // label: 'Pulmonary Status',
                key: 'pulmonaryStatus',
                controlType: 'radio',
                options: [
                    'Congested',
                    'Progressive cough',
                    'Tracheostomy',
                    'Face Mask',
                    'Ventilatory',
                    'Dependent',
                    'Other',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryLEDemaFields() {
        return [
            {
                // label: 'LE Dema',
                key: 'leDema',
                controlType: 'input',
                canBeAGoal: true,
                validationType: 'number',
                placeholder: 'Measurement in cm',
                scaleInterpretation: 'Less is best',
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryBreathingPatternFields() {
        return [
            {
                // label: 'Breathing Pattern',
                key: 'breathingPattern',
                controlType: 'checkbox',
                options: [
                    { text: 'Apnea', isChecked: false },
                    { text: 'Orthopnea', isChecked: false },
                    { text: 'Dyspnea', isChecked: false },
                    { text: 'Eupnea', isChecked: false },
                    { text: 'Diaphragmatic', isChecked: false },
                    { text: 'Costal breathing', isChecked: false },
                    { text: 'Hyperpnea', isChecked: false },
                    { text: 'Shortness of Breath While Lying Flat', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryLungSoundFields() {
        return [
            {
                // label: 'Lungs Sound',
                key: 'lungsSound',
                controlType: 'checkbox',
                options: [
                    { text: 'Regular', isChecked: false },
                    { text: 'Wheezing', isChecked: false },
                    { text: 'Crackling', isChecked: false },
                    { text: 'Rhonchi', isChecked: false },
                    { text: 'Stridor', isChecked: false },
                    { text: 'Pleural rub', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCardiopulmonaryCompensatoryStrategyFields() {
        return [
            {
                // label: 'Compensatory Strategies',
                key: 'compensatoryStrategies',
                controlType: 'checkbox',
                options: [
                    { text: 'Energy conservation techniques', isChecked: false },
                    { text: 'ADL assistive devices', isChecked: false },
                    { text: 'Home environment modifications', isChecked: false },
                    { text: 'Other', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalImpairmentsList() {
        return [
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.POSTURAL_ALIGNMENT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.MUSCLE_TONE_UE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.MUSCLE_TONE_LE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.MUSCLE_TONE_MODIFIED_ASHWORTH_UE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.MUSCLE_TONE_MODIFIED_ASHWORTH_LE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.INVOLUNTARY_MOVEMENTS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.WEIGHT_BEARING_STATUS_UE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.WEIGHT_BEARING_STATUS_LE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.WEIGHT_SHIFT_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.FALL_RISK_ASSESSMENT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.BALANCE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.REACTIVE_STRATEGIES,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.OVERALL_COORDINATION_UE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.OVERALL_COORDINATION_LE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.SOFT_TISSUE_SKIN_INTEGRITY,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.EDEMA,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.PITTING_EDEMA,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.REFLEXES_UE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.REFLEXES_LE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.LEG_LENGTH_DISCREPANCY,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.LIMB_GIRTH_MEASUREMENT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.DOES_CLIENT_EXPERIENCE_INCONTINENCE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.PATHOLOGICAL_GAIT_DEVIATION,
                existsIn: ['PT'],
                isCompleted: false,
            },
            {
                text: NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.GAIT_DEVIATIONS,
                existsIn: ['PT'],
                isCompleted: false,
            },
        ];
    }
    getNeuroMusculoSkeletalPosturalAlignmentFields() {
        return [
            {
                // label: 'Postural Alignment',
                key: 'posturalAlignment',
                controlType: 'checkbox',
                options: [
                    { text: 'Assymetric', isChecked: false },
                    { text: 'Rounded shoulders', isChecked: false },
                    { text: 'Thoracic hyperkyphosis', isChecked: false },
                    { text: 'Scoliosis', isChecked: false },
                    { text: 'Lumbar hyperlordosis', isChecked: false },
                    { text: 'Lumbar hyperkyphosis', isChecked: false },
                    { text: 'R/L hip asymmetry', isChecked: false },
                    { text: 'R/L hip overly rotated internally', isChecked: false },
                    { text: 'R/L hip overly rotated externally', isChecked: false },
                    { text: 'R/L knee valgus', isChecked: false },
                    { text: 'R/L knee varus', isChecked: false },
                    { text: 'R/L knee hyperextension', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalMuscleToneFields() {
        return [
            {
                // label: 'Muscle Tone UE',
                key: 'muscleTone',
                controlType: 'radio',
                side: 'LHS',
                options: ['Flacid', 'Hypotonic', 'Normal response', 'Hypertonic'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                // label: 'Muscle Tone UE',
                key: 'muscleTone',
                controlType: 'radio',
                side: 'RHS',
                options: ['Flacid', 'Hypotonic', 'Normal response', 'Hypertonic'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalMuscleToneModifiedToneFields() {
        return [
            {
                // label: 'Muscle Tone Modified',
                key: 'muscleToneModified',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    '0 - No increase in tone',
                    '1 - Slight increase in tone/catch and release at end of ROM',
                    '1+ - Slight increase in tone through ROM, but affected part moved easily',
                    '2 - More marked increase in tone through ROM, but affected part moved easily',
                    '3 - Considerable increase in tone, passive movement difficult',
                    '4 - Affected part in rigid flexion and extension',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                // label: 'Muscle Tone Modified',
                key: 'muscleToneModified',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    '0 - No increase in tone',
                    '1 - Slight increase in tone/catch and release at end of ROM',
                    '1+ - Slight increase in tone through ROM, but affected part moved easily',
                    '2 - More marked increase in tone through ROM, but affected part moved easily',
                    '3 - Considerable increase in tone, passive movement difficult',
                    '4 - Affected part in rigid flexion and extension',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalInvoluntaryMovementFields() {
        return [
            {
                // label: 'Involuntary Movements',
                key: 'involuntaryMovement',
                controlType: 'radio',
                options: ['Tremor', 'Clonus', 'Chorea', 'Associated Reactions'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalWeightBearingFields() {
        return [
            {
                // label: 'Weight Bearing Status',
                key: 'weightBearingStatus',
                controlType: 'radio',
                side: 'LHS',
                options: ['Full WB', 'Partial WB', 'Non Weight Bearing', 'Toe-Touch'],
                // Marking canBeAGoal: false for the time being, as we don't have any measurements for its data
                canBeAGoal: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                // label: 'Weight Bearing Status',
                key: 'weightBearingStatus',
                controlType: 'radio',
                side: 'RHS',
                options: ['Full WB', 'Partial WB', 'Non Weight Bearing', 'Toe-Touch'],
                // Marking canBeAGoal: false for the time being, as we don't have any measurements for its data
                canBeAGoal: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalWeightShiftFields() {
        return [
            {
                // label: 'Weight Shift',
                key: 'weightShift',
                controlType: 'radio',
                side: 'LHS',
                options: ['0-25%', '26-50%', '51-75%', '76-100%'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                // label: 'Weight Shift',
                key: 'weightShift',
                controlType: 'radio',
                side: 'RHS',
                options: ['0-25%', '26-50%', '51-75%', '76-100%'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalFallRiskAssessmentFields() {
        return [
            {
                label: 'Has the client fallen in the last year',
                key: 'Has the client fallen in the last year',
                controlType: 'radio',
                options: ['Yes', 'No', 'Unknown'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Does the client feel unsteady when standing',
                key: 'Does the client feel unsteady when standing',
                controlType: 'radio',
                options: ['Yes', 'No', 'Unknown'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Does the client feel unsteady when walking',
                key: '   Does the client feel unsteady when walking',
                controlType: 'radio',
                options: ['Yes', 'No', 'Unknown'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Does the client worry about falling ',
                key: 'Does the client worry about falling ',
                controlType: 'radio',
                options: ['Yes', 'No', 'Unknown'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalBalanceFields() {
        return [
            {
                label: 'Static Sitting Balance',
                key: 'staticSittingBalance',
                controlType: 'radio',
                options: ['WFL', 'WNL', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Dynamic Sitting Balance',
                key: 'dynamicSittingBalance',
                controlType: 'radio',
                options: ['WFL', 'WNL', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Static Standing Balance',
                key: 'staticStandingBalance',
                controlType: 'radio',
                options: ['WFL', 'WNL', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Dynamic Standing Balance',
                key: 'dynamicStandingBalance',
                controlType: 'radio',
                options: ['WFL', 'WNL', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Timed Sitting with UE support',
                key: 'timedSittingWithUESupport',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Timed Sitting Unsupported',
                key: 'timedSittingUnsupported',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Timed Standing with UE support',
                key: 'timedStandingWithUESupport',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Timed Standing Unsupported',
                key: 'timedStandingUnsupported',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Romberg Test',
                key: 'rombergTest',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Sharpened/Tandem Romberg Test',
                key: 'sharpenedTest',
                controlType: 'input',
                placeholder: 'Time in seconds',
                validationType: 'number',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalReactiveStrategiesFields() {
        return [
            {
                label: 'Ankle Strategies',
                key: 'ankleStrategies',
                controlType: 'radio',
                options: ['Intact', 'Mildly delayed', 'Moderately delayed', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Hip Strategies',
                key: 'hipStrategies',
                controlType: 'radio',
                options: ['Intact', 'Mildly delayed', 'Moderately delayed', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Stepping Strategies',
                key: 'steppingStrategies',
                controlType: 'radio',
                options: ['Intact', 'Mildly delayed', 'Moderately delayed', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Reach to Grasp Strategies',
                key: 'reachToGraspStrategies',
                controlType: 'radio',
                options: ['Intact', 'Mildly delayed', 'Moderately delayed', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalOverallCoordinationUE() {
        return [
            {
                // label: 'Overall coordination Upper Extremity',
                key: 'overallCoordination',
                controlType: 'radio',
                side: 'LHS',
                options: ['WNL', 'Mildly Impaired', 'Moderately Impaired', 'Severely Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalOverallCoordinationLE() {
        return [
            {
                // label: 'Overall coordination Lower Extremity',
                key: 'overallCoordination',
                side: 'RHS',
                controlType: 'radio',
                options: ['WNL', 'Mildly Impaired', 'Moderately Impaired', 'Severely Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalSoftTissueFields() {
        return [
            {
                // label: 'Soft Tissue/Skin Integrity',
                key: 'softTissue',
                controlType: 'checkbox',
                options: [
                    { text: 'Reddened areas', isChecked: false },
                    { text: 'Bruising', isChecked: false },
                    { text: 'Tears', isChecked: false },
                    { text: 'Wounds', isChecked: false },
                    { text: 'Other', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalEdemaFields() {
        return [
            {
                // label: 'Edema',
                key: 'edema',
                controlType: 'checkbox',
                options: [
                    { text: 'None', isChecked: false },
                    { text: 'Slight', isChecked: false },
                    { text: 'Weeping edema', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalPittingEdemaFields() {
        return [
            {
                // label: 'Pitting Edema',
                key: 'pittingEdema',
                controlType: 'radio',
                options: [
                    '1+ = trace_Pitting/impression of 2 millimeters(mm) or less, rebounding immediately. No visible deformity',
                    '2+ = mild_Swelling with no visible deformity. The pit is between 2 mm and 4 mm and takes up to 15 seconds to rebound',
                    '3+ = Moderate_A noticeably deeper pit of 4 mm to 6 mm that lasts as long as 30 seconds. Swelling is more intense in the surrounding extremity',
                    '4+ =Severe_A pit of 6 mm to 8 mm in depth that takes more than 30 seconds to disappear, accompanied by visible deformity and swelling in the area',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalReflexesUEFields() {
        return [
            {
                label: 'Biceps',
                key: 'biceps',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Radial brachialis',
                key: 'radialBrachialis',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Triceps',
                key: 'triceps',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Biceps',
                key: 'biceps',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Radial brachialis',
                key: 'radialBrachialis',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Triceps',
                key: 'triceps',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalReflexesLEFields() {
        return [
            {
                label: 'Quadriceps knee jerk',
                key: 'quadricepsKneeJerk',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Ankle jerk',
                key: 'ankleJerk',
                controlType: 'radio',
                side: 'LHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                // eslint-disable-next-line spellcheck/spell-checker
                label: "Plantar Response (Babinski's Sign)",
                key: 'plantarResponse',
                controlType: 'radio',
                side: 'LHS',
                options: ['Present', 'Not Present'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Quadriceps knee jerk',
                key: 'quadricepsKneeJerk',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Ankle jerk',
                key: 'ankleJerk',
                controlType: 'radio',
                side: 'RHS',
                options: [
                    'Absent or absent reflex (0+)',
                    'Trace of decreased response (1+)',
                    'Normal response (2+)',
                    'Exaggerated or brisk response (3+)',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                // eslint-disable-next-line spellcheck/spell-checker
                label: "Plantar Response (Babinski's Sign)",
                key: 'plantarResponse',
                controlType: 'radio',
                side: 'RHS',
                options: ['Present', 'Not Present'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }

    getNeuroMusculoSkeletalActivityToleranceFields() {
        return [
            {
                label: 'Activity Tolerance Sitting with back unsupported',
                key: 'sittingWithBack',
                controlType: 'n-inputs',
                validationType: 'number',
                placeholder: 'Time in seconds',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Activity Tolerance Standing without UE support',
                key: 'standingWithoutUESupport',
                controlType: 'n-inputs',
                validationType: 'number',
                placeholder: 'Time in seconds',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Activity Tolerance Standing WITH UE support',
                key: 'standingWithUESupport',
                controlType: 'n-inputs',
                validationType: 'number',
                placeholder: 'Time in seconds',
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalLegLengthDiscrepancyFields() {
        return [
            {
                label: 'Discrepancy',
                key: 'legLengthDiscrepancy',
                controlType: 'select',
                options: ['Anatomical (structural)', 'Functional (non-structural shortening)'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Length',
                key: 'length',
                controlType: 'input',
                dependsOn: 'legLengthDiscrepancy',
                placeholder: 'Measurement in inches',
                validationType: 'number',
                canBeAGoal: false,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalLimbGirthMeasurementFields() {
        return [
            {
                label: 'Measurement',
                key: 'limbGirthMeasurement',
                controlType: 'select',
                options: ['R/L arm', 'R/L forearm', 'R/L thigh', 'R/L calf', 'Other'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Length',
                key: 'length',
                controlType: 'input',
                dependsOn: 'limbGirthMeasurement',
                validationType: 'number',
                placeholder: 'Measurement in inches',
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalClientExperienceFields() {
        return [
            {
                // label: 'Does client experience Incontinence',
                key: 'Does client experience Incontinence',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalPathologicalGaitPatternFields() {
        return [
            {
                // label: 'Pathological Gait Patterns',
                key: 'pathologicalGaitPatterns',
                controlType: 'checkbox',
                options: [
                    { text: 'Antalgic', isChecked: false },
                    { text: 'Leg Length Discrepancy', isChecked: false },
                    { text: 'Trendelenburg', isChecked: false },
                    { text: 'Posterior Lurch', isChecked: false },
                    { text: 'Hip Circumduction', isChecked: false },
                    { text: 'Cerebellar Ataxia', isChecked: false },
                    { text: 'Parkinsonian', isChecked: false },
                    { text: 'Steppage', isChecked: false },
                    { text: 'Vestibular Ataxia', isChecked: false },
                    { text: 'Sensory Ataxic', isChecked: false },
                    { text: 'Hyperkinetic', isChecked: false },
                    { text: 'Waddling', isChecked: false },
                    { text: 'Spastic Hemiparetic', isChecked: false },
                    { text: 'Spastic diplegic (scissors gait)', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getNeuroMusculoSkeletalGaitDeviations() {
        return [
            {
                // label: 'Gait Deviations',
                key: 'gaitDeviations',
                controlType: 'checkbox',
                options: [
                    { text: 'Foot drop', isChecked: false },
                    { text: 'High Steppage', isChecked: false },
                    { text: 'Circumduction', isChecked: false },
                    { text: 'Vaulting', isChecked: false },
                    { text: 'Hip Hike', isChecked: false },
                    { text: 'Knee Hyperextension', isChecked: false },
                    { text: 'Knee Buckling', isChecked: false },
                    { text: 'Forward Trunk', isChecked: false },
                    { text: 'Trunk Extension', isChecked: false },
                    { text: 'Pelvic Drop', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainImpairmentsList() {
        return [
            {
                text: PAIN.LOCATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.PAIN_EFFECTS_ON_FUNCTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.FREQUENCY_OF_PAIN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.PAIN_SCALES,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.PAIN_TYPE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.PAIN_DESCRIPTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.WHAT_RELIEVES_PAIN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.WHAT_EXACERBATES_PAIN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.MEDICATIONS_TO_RELIEVE_PAIN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: PAIN.IS_SKILLED_THERAPY_NEEDED_TO_ADDRESS_PAIN,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
        ];
    }
    getPainLocationFields() {
        return [
            {
                // label: 'Location',
                controlType: 'checkbox',
                key: 'location',
                options: [
                    { text: 'Head', isChecked: false },
                    { text: 'cervical spine', isChecked: false },
                    { text: 'R/L shoulder', isChecked: false },
                    { text: 'R/L arm', isChecked: false },
                    { text: 'R/L hand', isChecked: false },
                    { text: 'thoracic spine', isChecked: false },
                    { text: 'lumbar spine', isChecked: false },
                    { text: 'R/L hip', isChecked: false },
                    { text: 'R/L knee', isChecked: false },
                    { text: 'R/L ankle coplex', isChecked: false },
                    { text: 'R/L foot', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainEffectsFields() {
        return [
            {
                label: 'Does the patient have pain that interferes with function?',
                key: 'Does the patient have pain that interferes with function?',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Does the patient have pain that interferes with sleep? ',
                key: 'Does the patient have pain that interferes with sleep? ',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainFrequencyFields() {
        return [
            {
                // label: 'Frequency of Pain',
                key: 'frequencyOfPain',
                controlType: 'radio',
                options: [
                    'Constant',
                    'Hourly',
                    'Daily',
                    'Intermittent',
                    'Several times per week',
                    'Several times per month',
                    'Other',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainScaleFields() {
        return [
            {
                label: 'Numerical Rating Scale',
                key: 'numericalRatingScale',
                controlType: 'radio',
                options: ['0 = None', '1-3 = Mild', '4-6 = Moderate', '7-10 = Severe'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Visual Analog Scale',
                key: 'verbalPainIntensityScale',
                controlType: 'slider',
                options: {
                    showTicks: true,
                    showTicksValues: false,
                    showSelectionBar: true,
                    hidePointerLabels: true,
                    hideLimitLabels: true,
                    stepsArray: [
                        { value: 0, legend: 'No Pain' },
                        { value: 1, legend: '1' },
                        { value: 2, legend: '2' },
                        { value: 3, legend: '3' },
                        { value: 4, legend: '4' },
                        { value: 5, legend: '5' },
                        { value: 6, legend: '6' },
                        { value: 7, legend: '7' },
                        { value: 8, legend: '8' },
                        { value: 9, legend: '9' },
                        { value: 10, legend: 'Worst Possible Pain' },
                    ],
                } as any,
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Wong-Baker Faces Pain Scale',
                key: 'wongBakerFacesPainScale',
                controlType: 'image',
                options: [
                    {
                        imagePath: 'assets/images/face-1.PNG',
                        value: 0,
                    },
                    {
                        imagePath: 'assets/images/face-2.PNG',
                        value: 2,
                    },
                    {
                        imagePath: 'assets/images/face-3.PNG',
                        value: 4,
                    },
                    {
                        imagePath: 'assets/images/face-4.PNG',
                        value: 6,
                    },
                    {
                        imagePath: 'assets/images/face-5.PNG',
                        value: 8,
                    },
                    {
                        imagePath: 'assets/images/face-6.PNG',
                        value: 10,
                    },
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainTypeFields() {
        return [
            {
                // label: 'Pain Type',
                key: 'painType',
                controlType: 'checkbox',
                options: [
                    { text: 'Generalized pain', isChecked: false },
                    { text: 'Pain at rest', isChecked: false },
                    { text: 'Pain with movement' },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainDescriptionFields() {
        return [
            {
                // label: 'Pain Description',
                key: 'painDescription',
                controlType: 'checkbox',
                options: [
                    { text: 'Ache', isChecked: false },
                    { text: 'Burning', isChecked: false },
                    { text: 'Chronic', isChecked: false },
                    { text: 'Conditional', isChecked: false },
                    { text: 'Discomfort', isChecked: false },
                    { text: 'Distressing', isChecked: false },
                    { text: 'Dull ache', isChecked: false },
                    { text: 'Exhausting', isChecked: false },
                    { text: 'Excruciating', isChecked: false },
                    { text: 'Gnawing', isChecked: false },
                    { text: 'Heavy', isChecked: false },
                    { text: 'Sharp', isChecked: false },
                    { text: 'Sharp-quick', isChecked: false },
                    { text: 'Sharp-long lasting', isChecked: false },
                    { text: 'Shooting', isChecked: false },
                    { text: 'Sickening', isChecked: false },
                    { text: 'Stabbing', isChecked: false },
                    { text: 'Throbbing', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainRelieveFields() {
        return [
            {
                // label: 'What relieves pain?',
                key: 'What relieves pain?',
                controlType: 'checkbox',
                options: [
                    { text: 'Medications', isChecked: false },
                    { text: 'Rest', isChecked: false },
                    { text: 'Movement', isChecked: false },
                    { text: 'Sitting', isChecked: false },
                    { text: 'Walking', isChecked: false },
                    { text: 'Other', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainExacerbateFields() {
        return [
            {
                // label: 'What exacerbates pain?',
                key: 'What exacerbates pain?',
                controlType: 'checkbox',
                options: [
                    { text: 'Rest', isChecked: false },
                    { text: 'Movement', isChecked: false },
                    { text: 'Temperature', isChecked: false },
                    { text: 'Stress', isChecked: false },
                    { text: 'Sitting', isChecked: false },
                    { text: 'Walking', isChecked: false },
                    { text: 'Other', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainMedicationsToRelievePainFields() {
        return [
            {
                // label: 'Medications used to relief pain?',
                key: 'Medications used to relief pain?',
                controlType: 'checkbox',
                options: [
                    { text: 'OTC NSAIDs', isChecked: false },
                    { text: 'OTC acetaminophen', isChecked: false },
                    { text: 'Analgesics', isChecked: false },
                    { text: 'Narcotics', isChecked: false },
                    { text: 'Opioids', isChecked: false },
                    { text: 'Muscle relaxers', isChecked: false },
                    { text: 'Steroids', isChecked: false },
                    { text: 'Topical', isChecked: false },
                    { text: 'Cannabis', isChecked: false },
                    { text: 'Other', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getPainSkilledTherapyFields() {
        return [
            {
                // label: 'Is skilled therapy needed to address pain?',
                key: 'Is skilled therapy needed to address pain?',
                controlType: 'radio',
                options: ['Yes', 'No/Nursing is addressing', 'Other'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSensoryImpairmentsList() {
        return [
            {
                text: SENSORY.SENSATION_UE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: SENSORY.SENSATION_LE_L_R,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: SENSORY.PROPRIOCEPTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
        ];
    }
    getSensorySensationFields() {
        return [
            {
                label: 'Light Touch',
                key: 'lightTouch',
                controlType: 'radio',
                side: 'LHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Light Touch',
                key: 'lightTouch',
                controlType: 'radio',
                side: 'RHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Pressure',
                key: 'pressure',
                controlType: 'radio',
                side: 'LHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Pressure',
                key: 'pressure',
                controlType: 'radio',
                side: 'RHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Deep Pressure',
                key: 'deepPressure',
                controlType: 'radio',
                side: 'LHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Deep Pressure',
                key: 'deepPressure',
                controlType: 'radio',
                side: 'RHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Vibration',
                key: 'vibration',
                controlType: 'radio',
                side: 'LHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Vibration',
                key: 'vibration',
                controlType: 'radio',
                side: 'RHS',
                options: ['Intact', 'Diminished', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSensoryProprioceptionFields() {
        return [
            {
                label: 'Contralateral Joint Matching Task',
                key: 'contralateralJointMatchingTask',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Rapidly Alternating Movements',
                key: 'rapidlyAlternatingMovements',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Sharpened/Tandem Romberg Test',
                key: 'sharpenedTest',
                controlType: 'radio',
                options: ['Pass', 'Fail'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Heel-shin',
                key: 'heelShin',
                controlType: 'radio',
                options: ['Pass', 'Fail'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Ataxia',
                key: 'ataxia',
                controlType: 'radio',
                options: ['Present', 'Not Present'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Finger-nose-finger Test',
                key: 'fingerNoseFingerTest',
                controlType: 'radio',
                options: ['Pass', 'Fail'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Distal Proprioception Test',
                key: 'distalProprioceptionTest',
                controlType: 'radio',
                options: ['Pass', 'Fail'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionImpairmentsList() {
        return [
            {
                text: COGNITION.LEVEL_OF_AROUSAL_ALERTNESS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ATTENTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ORIENTATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.SEQUENCING,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.PROBLEM_SOLVING,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.SAFETY_AWARENESS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ORGANIZATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.PLANNING,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ABSTRACT_THINKING,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.JUDGEMENT_INSIGHT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.FOLLOW_DIRECTIONS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ABILITY_TO_LEARN_NEW_INFORMATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.ABILITY_TO_UNDERSTAND_OTHERS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.SHORT_TERM_MEMORY,
                existsIn: ['OT'],
                isCompleted: false,
            },
            {
                text: COGNITION.LONG_TERM_MEMORY,
                existsIn: ['OT'],
                isCompleted: false,
            },
        ];
    }
    getCognitionLevelOfArousalFields() {
        return [
            {
                // label: 'Level of Arousal/Alertness',
                key: 'levelOfArousal',
                controlType: 'radio',
                options: ['Conscious/Normal', 'Confused', 'Delirious', 'Somnolent', 'Obtunded', 'Stuporous'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionAttentionFields() {
        return [
            {
                label: 'Focused Attention',
                key: 'focusedAttention',
                controlType: 'checkbox',
                options: [
                    { text: 'Intact', isChecked: false },
                    { text: 'Minimally impaired', isChecked: false },
                    { text: 'Moderately impaired', isChecked: false },
                    { text: 'Severely impaired', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Sustained Attention',
                key: 'sustainedAttention',
                controlType: 'checkbox',
                options: [
                    { text: 'Intact', isChecked: false },
                    { text: 'Minimally impaired', isChecked: false },
                    { text: 'Moderately impaired', isChecked: false },
                    { text: 'Severely impaired', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Selective Attention',
                key: 'selectiveAttention',
                controlType: 'checkbox',
                options: [
                    { text: 'Intact', isChecked: false },
                    { text: 'Minimally impaired', isChecked: false },
                    { text: 'Moderately impaired', isChecked: false },
                    { text: 'Severely impaired', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Alternating Attention',
                key: 'alternatingAttention',
                controlType: 'checkbox',
                options: [
                    { text: 'Intact', isChecked: false },
                    { text: 'Minimally impaired', isChecked: false },
                    { text: 'Moderately impaired', isChecked: false },
                    { text: 'Severely impaired', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Divided Attention',
                key: 'dividedAttention',
                controlType: 'checkbox',
                options: [
                    { text: 'Intact', isChecked: false },
                    { text: 'Minimally impaired', isChecked: false },
                    { text: 'Moderately impaired', isChecked: false },
                    { text: 'Severely impaired', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionOrientationFields() {
        return [
            {
                // label: 'Orientation',
                key: 'orientation',
                controlType: 'checkbox',
                options: [
                    { text: 'Person', isChecked: false },
                    { text: 'Place', isChecked: false },
                    { text: 'Time', isChecked: false },
                    { text: 'Situation', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionSequencingFields() {
        return [
            {
                // label: 'Sequencing',
                key: 'sequencing',
                controlType: 'radio',
                options: ['1 - Intact', '2 - Minimally Impaired', '3 - Moderately Impaired', '4 - Severely Impaired'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionProblemSolvingFields() {
        return [
            {
                // label: 'Problem Solving',
                key: 'problemSolving',
                controlType: 'radio',
                options: [
                    '1 - Independent (decisions consistent/reasonable)',
                    '2 - Modified Independent (some difficulty in new situation)',
                    '3 - Moderately Impaired (decisions are poor cues and supervision required)',
                    '4 - Severely Impaired (never/rarely made decisions)',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionSafetyAwarenessFields() {
        return [
            {
                // label: 'Safety Awareness',
                key: 'safetyAwareness',
                controlType: 'radio',
                options: ['1 - Intact', '2 - Minimally Impaired', '3 - Moderately Impaired', '4 - Severely Impaired'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionOrganizationFields() {
        return [
            {
                // label: 'Organization',
                key: 'organization',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionPlanningFields() {
        return [
            {
                // label: 'Planning',
                key: 'planning',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionAbstractThinkingFields() {
        return [
            {
                // label: 'Abstract Thinking',
                key: 'abstractThinking',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionJudgementInsightFields() {
        return [
            {
                // label: 'Judgement/Insight',
                key: 'judgment',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionDirectionsFields() {
        return [
            {
                // label: 'Follows Directions',
                key: 'followsDirections',
                controlType: 'radio',
                options: ['1 Step commands', '2 Step commands', '3+ Step commands'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionLearnNewInformationFields() {
        return [
            {
                // label: 'Ability to learn new information',
                key: 'abilityToLearnNewInformation',
                controlType: 'radio',
                options: ['Consistent', 'Sometimes', 'Never'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionUnderstandOthersFields() {
        return [
            {
                // label: 'Ability to understand others',
                key: 'abilityToUnderstandOthers',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionShortTermMemoryFields() {
        return [
            {
                // label: 'Short Term Memory',
                key: 'shortTermMemory',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely impaired'],
                canBeAGoal: false,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getCognitionLongTermMemoryFields() {
        return [
            {
                // label: 'Long Term Memory',
                key: 'longTermMemory',
                controlType: 'radio',
                options: ['Intact', 'Minimally impaired', 'Moderately impaired', 'Severely impaired'],
                canBeAGoal: false,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionImpairmentsList() {
        return [
            {
                text: VISION_PERCEPTION.VISUAL_ACUITY,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.VISUAL_FIELD_CUT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.VISUAL_FIXATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.GAZE_STABILIZATION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.TRACKING_AND_SCANNING,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.DEPTH_PERCEPTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
            {
                text: VISION_PERCEPTION.NEGLECT_SYNDROMES,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
            },
        ];
    }
    getVisionPerceptionVisualAcuityFields() {
        return [
            {
                // label: 'Visual Acuity',
                key: 'visualAcuity',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionVisualFieldCut() {
        return [
            {
                // label: 'Visual Field Cut',
                key: 'visualFieldCut',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionVisualFixationFields() {
        return [
            {
                // label: 'Visual Fixation',
                key: 'visualFixation',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionGazeStabilizationFields() {
        return [
            {
                // label: 'Gaze Stabilization',
                key: 'gazeStabilization',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionTrackingAndScanningFields() {
        return [
            {
                // label: 'Tracking and Scanning',
                key: 'trackingAndScanning',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionDepthPerceptionFields() {
        return [
            {
                // label: 'Depth Perception',
                key: 'depthPerception',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVisionPerceptionNeglectSyndromesFields() {
        return [
            {
                // label: 'Neglect Syndromes',
                key: 'neglectSyndromes',
                controlType: 'radio',
                options: ['Left side Neglect', 'Right side Neglect', 'Other'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationImpairmentsList() {
        return [
            {
                text: ORAL_MOTOR_EXAMINATION.FACIAL_SYMMETRY,
                fields: this.getOralMotorExaminationFacialSymmetryFields(),
                form: this.toFormGroup(this.getOralMotorExaminationFacialSymmetryFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.FACIAL_SENSATION,
                fields: this.getOralMotorExaminationFacialSensationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationFacialSensationFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.MANDIBULAR_STRENGTH,
                fields: this.getOralMotorExaminationMandibularStrengthFields(),
                form: this.toFormGroup(this.getOralMotorExaminationMandibularStrengthFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.MANDIBULAR_TONE,
                fields: this.getOralMotorExaminationMandibularToneFields(),
                form: this.toFormGroup(this.getOralMotorExaminationMandibularToneFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.MANDIBULAR_ROM,
                fields: this.getOralMotorExaminationMandibularRomFields(),
                form: this.toFormGroup(this.getOralMotorExaminationMandibularRomFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.MANDIBULAR_COORDINATION,
                fields: this.getOralMotorExaminationMandibularCoordinationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationMandibularCoordinationFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.SALIVA_MANAGEMENT,
                fields: this.getOralMotorExaminationSalivaFields(),
                form: this.toFormGroup(this.getOralMotorExaminationSalivaFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LINGUAL_SENSATION,
                fields: this.getOralMotorExaminationLingualSensationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLingualSensationFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LINGUAL_TONE,
                fields: this.getOralMotorExaminationLingualToneFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLingualToneFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LINGUAL_STRENGTH,
                fields: this.getOralMotorExaminationLingualStrengthFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLingualStrengthFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LINGUAL_ROM,
                fields: this.getOralMotorExaminationLingualRomFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLingualRomFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LINGUAL_COORDINATION,
                fields: this.getOralMotorExaminationLingualCoordinationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLingualCoordinationFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LABIAL_SENSATION,
                fields: this.getOralMotorExaminationLabialSensationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLabialSensationFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LABIAL_TONE,
                fields: this.getOralMotorExaminationLabialToneFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLabialToneFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LABIAL_STRENGTH,
                fields: this.getOralMotorExaminationLabialStrengthFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLabialStrengthFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LABIAL_ROM,
                fields: this.getOralMotorExaminationLabialRomFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLabialRomFields()),
                isCompleted: false,
            },
            {
                text: ORAL_MOTOR_EXAMINATION.LABIAL_COORDINATION,
                fields: this.getOralMotorExaminationLabialCoordinationFields(),
                form: this.toFormGroup(this.getOralMotorExaminationLabialCoordinationFields()),
                isCompleted: false,
            },
        ];
    }
    getOralMotorExaminationFacialSymmetryFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.FACIAL_SYMMETRY,
                key: 'facialSymmetry',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationFacialSensationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.FACIAL_SENSATION,
                key: 'facialSensation',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationMandibularStrengthFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.MANDIBULAR_STRENGTH,
                key: 'mandibularStrength',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationMandibularRomFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.MANDIBULAR_ROM,
                key: 'mandibularRom',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationMandibularToneFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.MANDIBULAR_TONE,
                key: 'mandibularTone',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationMandibularCoordinationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.MANDIBULAR_COORDINATION,
                key: 'mandibularCoordination',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationSalivaFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.SALIVA_MANAGEMENT,
                key: 'salivaManagement',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLingualSensationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LINGUAL_SENSATION,
                key: 'lingualSensation',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLingualToneFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LINGUAL_TONE,
                key: 'lingualTone',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLingualStrengthFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LINGUAL_STRENGTH,
                key: 'lingualStrength',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLingualRomFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LINGUAL_ROM,
                key: 'lingualRom',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLingualCoordinationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LINGUAL_COORDINATION,
                key: 'lingualCoordination',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLabialSensationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LABIAL_SENSATION,
                key: 'labialSensation',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLabialToneFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LABIAL_TONE,
                key: 'labialTone',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLabialStrengthFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LABIAL_STRENGTH,
                key: 'labialStrength',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLabialRomFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LABIAL_ROM,
                key: 'labialRom',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getOralMotorExaminationLabialCoordinationFields() {
        return [
            {
                // label: ORAL_MOTOR_EXAMINATION.LABIAL_COORDINATION,
                key: 'labialCoordination',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryComprehensionImpairmentsList() {
        return [
            {
                text: AUDITORY_COMPREHENSION.UNDERSTANDING_YES_NO,
                fields: this.getAuditoryUnderstandingYesNoQuestionFields(),
                form: this.toFormGroup(this.getAuditoryUnderstandingYesNoQuestionFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.FOLLOWING_COMMANDS,
                fields: this.getAuditoryFollowingCommandsFields(),
                form: this.toFormGroup(this.getAuditoryFollowingCommandsFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.DISCRIMINATING_BODY_PARTS,
                fields: this.getAuditoryDiscriminatingBodyPartsFields(),
                form: this.toFormGroup(this.getAuditoryDiscriminatingBodyPartsFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.DISCRIMINATING_OBJECTS,
                fields: this.getAuditoryDiscriminatingObjectsFields(),
                form: this.toFormGroup(this.getAuditoryDiscriminatingObjectsFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.UNDERSTANDING_OPEN_ENDED_QUESTIONS,
                fields: this.getAuditoryOpenEndedQuestionsFields(),
                form: this.toFormGroup(this.getAuditoryOpenEndedQuestionsFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.UNDERSTANDING_CONVERSATION,
                fields: this.getAuditoryUnderstandingConversationFields(),
                form: this.toFormGroup(this.getAuditoryUnderstandingConversationFields()),
                isCompleted: false,
            },
            {
                text: AUDITORY_COMPREHENSION.UNDERSTANDING_GESTURES,
                fields: this.getAuditoryUnderstandingGestureFields(),
                form: this.toFormGroup(this.getAuditoryUnderstandingGestureFields()),
                isCompleted: false,
            },
        ];
    }
    getAuditoryUnderstandingYesNoQuestionFields() {
        return [
            {
                key: 'understandingYesNoQuestions',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryFollowingCommandsFields() {
        return [
            {
                key: 'followingCommands',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryDiscriminatingBodyPartsFields() {
        return [
            {
                key: 'discriminatingBodyParts',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryDiscriminatingObjectsFields() {
        return [
            {
                key: 'discriminatingObjects',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryOpenEndedQuestionsFields() {
        return [
            {
                key: 'understandingOpenEndedQuestions',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryUnderstandingConversationFields() {
        return [
            {
                key: 'understandingConversation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getAuditoryUnderstandingGestureFields() {
        return [
            {
                key: 'understandingGestures',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingComprehensionImpairmentsList() {
        return [
            {
                text: READING_COMPREHENSION.LETTER_RECOGNITION,
                fields: this.getReadingLetterRecognitionFields(),
                form: this.toFormGroup(this.getReadingLetterRecognitionFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.WORD_OBJECT_MATCHING,
                fields: this.getReadingWordObjectMatchingFields(),
                form: this.toFormGroup(this.getReadingWordObjectMatchingFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.WORD_PICTURE_MATCHING,
                fields: this.getReadingWordPictureMatchingFields(),
                form: this.toFormGroup(this.getReadingWordPictureMatchingFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.PHRASES,
                fields: this.getReadingPhrasesFields(),
                form: this.toFormGroup(this.getReadingPhrasesFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.SENTENCES,
                fields: this.getReadingSentencesFields(),
                form: this.toFormGroup(this.getReadingSentencesFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.PARAGRAPHS,
                fields: this.getReadingParagraphsFields(),
                form: this.toFormGroup(this.getReadingParagraphsFields()),
                isCompleted: false,
            },
            {
                text: READING_COMPREHENSION.FUNCTIONAL_LANGUAGE,
                fields: this.getReadingFunctionalLanguageFields(),
                form: this.toFormGroup(this.getReadingFunctionalLanguageFields()),
                isCompleted: false,
            },
        ];
    }
    getReadingLetterRecognitionFields() {
        return [
            {
                key: 'letterRecognition',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingWordObjectMatchingFields() {
        return [
            {
                key: 'wordObjectMatching',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingWordPictureMatchingFields() {
        return [
            {
                key: 'wordPictureMatching',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingPhrasesFields() {
        return [
            {
                key: 'phrases',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingSentencesFields() {
        return [
            {
                key: 'sentences',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingParagraphsFields() {
        return [
            {
                key: 'paragraphs',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getReadingFunctionalLanguageFields() {
        return [
            {
                key: 'functionalLanguage',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageImpairmentsList() {
        return [
            {
                text: WRITTEN_LANGUAGE.SIGNATURE,
                fields: this.getWrittenLanguageSignatureFields(),
                form: this.toFormGroup(this.getWrittenLanguageSignatureFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.LETTERS_TO_SPOKEN_DICTATION,
                fields: this.getWrittenLanguageLettersToSpokenDictationFields(),
                form: this.toFormGroup(this.getWrittenLanguageLettersToSpokenDictationFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.LETTERS_TO_COPY,
                fields: this.getWrittenLanguageLettersToCopyFields(),
                form: this.toFormGroup(this.getWrittenLanguageLettersToCopyFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.WORDS_TO_DICTATION,
                fields: this.getWrittenLanguageWordsToDictationFields(),
                form: this.toFormGroup(this.getWrittenLanguageWordsToDictationFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.WORDS_TO_COPY,
                fields: this.getWrittenLanguageWordsToCopyFields(),
                form: this.toFormGroup(this.getWrittenLanguageWordsToCopyFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.SENTENCES_TO_DICTATION,
                fields: this.getWrittenLanguageSentencesToDictationFields(),
                form: this.toFormGroup(this.getWrittenLanguageSentencesToDictationFields()),
                isCompleted: false,
            },
            {
                text: WRITTEN_LANGUAGE.SENTENCES_TO_COPY,
                fields: this.getWrittenLanguageSentencesToCopyFields(),
                form: this.toFormGroup(this.getWrittenLanguageSentencesToCopyFields()),
                isCompleted: false,
            },
        ];
    }
    getWrittenLanguageSignatureFields() {
        return [
            {
                key: 'signature',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageLettersToSpokenDictationFields() {
        return [
            {
                key: 'spokenDictation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageLettersToCopyFields() {
        return [
            {
                key: 'lettersToCopy',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageWordsToDictationFields() {
        return [
            {
                key: 'wordsToDictation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageWordsToCopyFields() {
        return [
            {
                key: 'wordsToCopy',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageSentencesToDictationFields() {
        return [
            {
                key: 'sentencesToDictationFields',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getWrittenLanguageSentencesToCopyFields() {
        return [
            {
                key: 'sentencesToCopy',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionImpairmentsList() {
        return [
            {
                text: VERBAL_EXPRESSION.MAKING_NEEDS_KNOWN,
                fields: this.getVerbalExpressionMakingNeedsknownFields(),
                form: this.toFormGroup(this.getVerbalExpressionMakingNeedsknownFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.CONVERSATION,
                fields: this.getVerbalExpressionConversationFields(),
                form: this.toFormGroup(this.getVerbalExpressionConversationFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.AUTOMATIC_SPEECH,
                fields: this.getVerbalExpressionAutomaticSpeechFields(),
                form: this.toFormGroup(this.getVerbalExpressionAutomaticSpeechFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.SINGING,
                fields: this.getVerbalExpressionSingingFields(),
                form: this.toFormGroup(this.getVerbalExpressionSingingFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.REPEATING,
                fields: this.getVerbalExpressionRepeatingFields(),
                form: this.toFormGroup(this.getVerbalExpressionRepeatingFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.NAMING,
                fields: this.getVerbalExpressionNamingFields(),
                form: this.toFormGroup(this.getVerbalExpressionNamingFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.DESCRIBING,
                fields: this.getVerbalExpressionDescribingFields(),
                form: this.toFormGroup(this.getVerbalExpressionDescribingFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.EXPRESS_WITH_GESTURES,
                fields: this.getVerbalExpressionExpressWithGestureFields(),
                form: this.toFormGroup(this.getVerbalExpressionExpressWithGestureFields()),
                isCompleted: false,
            },
            {
                text: VERBAL_EXPRESSION.DOES_PATIENT_NEED_AAC,
                fields: this.getVerbalExpressionPatientNeedAACFields(),
                form: this.toFormGroup(this.getVerbalExpressionPatientNeedAACFields()),
                isCompleted: false,
            },
        ];
    }
    getVerbalExpressionMakingNeedsknownFields() {
        return [
            {
                key: 'makingNeedsKnown',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionConversationFields() {
        return [
            {
                key: 'conversation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionAutomaticSpeechFields() {
        return [
            {
                key: 'automaticSpeech',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionSingingFields() {
        return [
            {
                key: 'singing',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionRepeatingFields() {
        return [
            {
                key: 'repeating',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionNamingFields() {
        return [
            {
                key: 'naming',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionDescribingFields() {
        return [
            {
                key: 'describing',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionExpressWithGestureFields() {
        return [
            {
                key: 'expressWithGesture',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getVerbalExpressionPatientNeedAACFields() {
        return [
            {
                key: 'does patient need AAC?',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechImpairmentsList() {
        return [
            {
                text: MOTOR_SPEECH.ARTICULATION,
                fields: this.getMotorSpeechArticulationFields(),
                form: this.toFormGroup(this.getMotorSpeechArticulationFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.SPEECH_INTELLIGIBILITY,
                fields: this.getMotorSpeechIntelligibilityFields(),
                form: this.toFormGroup(this.getMotorSpeechIntelligibilityFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.SIMPLE_SENTENCES,
                fields: this.getMotorSpeechSimpleSentencesFields(),
                form: this.toFormGroup(this.getMotorSpeechSimpleSentencesFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.COMPLEX_SENTENCES,
                fields: this.getMotorSpeechComplexSentencesFields(),
                form: this.toFormGroup(this.getMotorSpeechComplexSentencesFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.SIMPLE_CONVERSATION,
                fields: this.getMotorSpeechSimpleConversationFields(),
                form: this.toFormGroup(this.getMotorSpeechSimpleConversationFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.COMPLEX_CONVERSATION,
                fields: this.getMotorSpeechSComplexConversationFields(),
                form: this.toFormGroup(this.getMotorSpeechSComplexConversationFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.RESONANCE_PROSODY,
                fields: this.getMotorSpeechResonanceProsodyFields(),
                form: this.toFormGroup(this.getMotorSpeechResonanceProsodyFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.VOCAL_INTENSITY,
                fields: this.getMotorSpeechVocalIntensityFields(),
                form: this.toFormGroup(this.getMotorSpeechVocalIntensityFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.SUSTAINED_PHONATION,
                fields: this.getMotorSpeechSustainedPhonationFields(),
                form: this.toFormGroup(this.getMotorSpeechSustainedPhonationFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.FLUENCY,
                fields: this.getMotorSpeechFluencyFields(),
                form: this.toFormGroup(this.getMotorSpeechFluencyFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.VERBAL_AGILITY_COORDINATION,
                fields: this.getMotorSpeechVerbalAgilityFields(),
                form: this.toFormGroup(this.getMotorSpeechVerbalAgilityFields()),
                isCompleted: false,
            },
            {
                text: MOTOR_SPEECH.FORMAL_DYSARTHRIA_APPRAXIA_ASSESSMENT,
                fields: this.getMotorSpeechFormalDysarthriaFields(),
                form: this.toFormGroup(this.getMotorSpeechFormalDysarthriaFields()),
                isCompleted: false,
            },
        ];
    }
    getMotorSpeechArticulationFields() {
        return [
            {
                key: 'articulation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechIntelligibilityFields() {
        return [
            {
                key: 'phonemes',
                label: 'Phonemes',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'words',
                label: 'Words',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechSimpleSentencesFields() {
        return [
            {
                key: 'simpleSentences',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechComplexSentencesFields() {
        return [
            {
                key: 'complexSentences',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechSimpleConversationFields() {
        return [
            {
                key: 'simpleConversation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechSComplexConversationFields() {
        return [
            {
                key: 'complexConversation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechResonanceProsodyFields() {
        return [
            {
                key: 'resonance',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechVocalIntensityFields() {
        return [
            {
                key: 'vocalIntensity',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechSustainedPhonationFields() {
        return [
            {
                key: 'sustainedPhonation',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechFluencyFields() {
        return [
            {
                key: 'fluency',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechVerbalAgilityFields() {
        return [
            {
                key: 'verbalAgility',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMotorSpeechFormalDysarthriaFields() {
        return [
            {
                label: '(AMR/SMR, FDA-2, ABA-2, SIR)',
                key: 'formalDysarthriaApraxia',
                controlType: 'text-area',
                canBeAGoal: true,
                isRegularAlike: true,
            },
        ];
    }
    getVoiceEvaluationImpairmentsList() {
        return [
            {
                text: VOICE_EVALUATION.INFORMAL_ASSESSMENT,
                fields: this.getVoiceEvaluationInformalAssessmentFields(),
                form: this.toFormGroup(this.getVoiceEvaluationInformalAssessmentFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.VOCAL_HYGIENE,
                fields: this.getVoiceEvaluationVocalHygieneFields(),
                form: this.toFormGroup(this.getVoiceEvaluationVocalHygieneFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.DAILY_VOCAL_ACTIVITIES,
                fields: this.getVoiceEvaluationDailyVocalFields(),
                form: this.toFormGroup(this.getVoiceEvaluationDailyVocalFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.REFLUX_HISTORY,
                fields: this.getVoiceEvaluationRefluxHistoryFields(),
                form: this.toFormGroup(this.getVoiceEvaluationRefluxHistoryFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.PITCH,
                fields: this.getVoiceEvaluationPitchFields(),
                form: this.toFormGroup(this.getVoiceEvaluationPitchFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.VOCAL_QUALITY,
                fields: this.getVoiceEvaluationVocalQualityFields(),
                form: this.toFormGroup(this.getVoiceEvaluationVocalQualityFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.POSTURE,
                fields: this.getVoiceEvaluationPostureFields(),
                form: this.toFormGroup(this.getVoiceEvaluationPostureFields()),
                isCompleted: false,
            },
            {
                text: VOICE_EVALUATION.FORMAL_VOICE_ASSESSMENT,
                fields: this.getVoiceEvaluationFormalVoiceAssessmentFields(),
                form: this.toFormGroup(this.getVoiceEvaluationFormalVoiceAssessmentFields()),
                isCompleted: false,
            },
        ];
    }
    getVoiceEvaluationInformalAssessmentFields() {
        return [
            {
                key: 'informalAssessment',
                controlType: 'text-area',
                canBeAGoal: true,
                isRegularAlike: true,
            },
        ];
    }
    getVoiceEvaluationVocalHygieneFields() {
        return [
            {
                label: 'Daily Water Intake',
                key: 'dailyWaterIntake',
                controlType: 'radio',
                options: ['<2 glasses', '3-7 glasses', '8+ glasses'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Daily Caffeine Intake',
                key: 'dailyCaffeineIntake',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Tobacco',
                key: 'tobacco',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Alcohol',
                key: 'alcohol',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVoiceEvaluationDailyVocalFields() {
        return [
            {
                key: 'dailyVocalActivities',
                controlType: 'text-area',
                canBeAGoal: true,
                isRegularAlike: true,
            },
        ];
    }
    getVoiceEvaluationRefluxHistoryFields() {
        return [
            {
                key: 'refluxHistory',
                controlType: 'text-area',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
        ];
    }
    getVoiceEvaluationPitchFields() {
        return [
            {
                key: 'pitch',
                controlType: 'radio',
                options: ['WFL', 'Reduced range', 'Pitch breaks'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVoiceEvaluationVocalQualityFields() {
        return [
            {
                key: 'vocalQuality',
                controlType: 'checkbox',
                options: [
                    { text: 'WFL', isChecked: false },
                    { text: 'Hoarse', isChecked: false },
                    { text: 'Harsh', isChecked: false },
                    { text: 'Strangled', isChecked: false },
                    { text: 'Wet', isChecked: false },
                    { text: 'Breathy', isChecked: false },
                    { text: 'Aphonic', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVoiceEvaluationPostureFields() {
        return [
            {
                key: 'posture',
                controlType: 'radio',
                options: ['WFL', 'Inadequate for speech'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getVoiceEvaluationFormalVoiceAssessmentFields() {
        return [
            {
                key: 'formalVoiceAssessment',
                controlType: 'radio',
                options: ['WFL', 'Min', 'Mod', 'Sev', 'Unable'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getGeneralProcessingImpairmentsList() {
        return [
            {
                text: GENERAL_PROCESSING.ATTENTION,
                fields: this.getGeneralProcessingAttentionFields(),
                form: this.toFormGroup(this.getGeneralProcessingAttentionFields()),
                isCompleted: false,
            },
            {
                text: GENERAL_PROCESSING.INSIGHT,
                fields: this.getGeneralProcessingInsightFields(),
                form: this.toFormGroup(this.getGeneralProcessingInsightFields()),
                isCompleted: false,
            },
            {
                text: GENERAL_PROCESSING.ORIENTATION,
                fields: this.getGeneralProcessingOrientationFields(),
                form: this.toFormGroup(this.getGeneralProcessingOrientationFields()),
                isCompleted: false,
            },
        ];
    }
    getGeneralProcessingAttentionFields() {
        return [
            {
                label: 'Sustained',
                key: 'sustained',
                controlType: 'radio',
                options: [
                    'WFL (40-60 min)',
                    'Minimal Impairment (20-40 min)',
                    'Moderate Impairment (10-20 min)',
                    'Severe Impairment (1-10 min)',
                    'Unable (<1 min)',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Selective',
                key: 'selective',
                controlType: 'radio',
                options: [
                    'WFL (40-60 min)',
                    'Minimal Impairment (20-40 min)',
                    'Moderate Impairment (10-20 min)',
                    'Severe Impairment (1-10 min)',
                    'Unable (<1 min)',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Alternating',
                key: 'alternating',
                controlType: 'radio',
                options: [
                    'WFL (40-60 min)',
                    'Minimal Impairment (20-40 min)',
                    'Moderate Impairment (10-20 min)',
                    'Severe Impairment (1-10 min)',
                    'Unable (<1 min)',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Divided',
                key: 'divided',
                controlType: 'radio',
                options: [
                    'WFL (40-60 min)',
                    'Minimal Impairment (20-40 min)',
                    'Moderate Impairment (10-20 min)',
                    'Severe Impairment (1-10 min)',
                    'Unable (<1 min)',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getGeneralProcessingInsightFields() {
        return [
            {
                key: 'insight',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getGeneralProcessingOrientationFields() {
        return [
            {
                key: 'orientation',
                controlType: 'checkbox',
                options: [
                    { text: 'Person', isChecked: false },
                    { text: 'Place', isChecked: false },
                    { text: 'Time', isChecked: false },
                    { text: 'Purpose', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryImpairmentsList() {
        return [
            {
                text: MEMORY.SHORT_TERM,
                fields: this.getMemoryShortTermFields(),
                form: this.toFormGroup(this.getMemoryShortTermFields()),
                isCompleted: false,
            },
            {
                text: MEMORY.LONG_TERM,
                fields: this.getMemoryLongTermFields(),
                form: this.toFormGroup(this.getMemoryLongTermFields()),
                isCompleted: false,
            },
            {
                text: MEMORY.PROCEDURAL,
                fields: this.getMemoryProceduralFields(),
                form: this.toFormGroup(this.getMemoryProceduralFields()),
                isCompleted: false,
            },
            {
                text: MEMORY.PROSPECTIVE,
                fields: this.getMemoryProspectiveFields(),
                form: this.toFormGroup(this.getMemoryProspectiveFields()),
                isCompleted: false,
            },
            {
                text: MEMORY.CATEGORIZATION,
                fields: this.getMemoryCategorizationFields(),
                form: this.toFormGroup(this.getMemoryCategorizationFields()),
                isCompleted: false,
            },
            {
                text: MEMORY.JUDGEMENT,
                fields: this.getMemoryJudgementFields(),
                form: this.toFormGroup(this.getMemoryJudgementFields()),
                isCompleted: false,
            },
        ];
    }
    getMemoryShortTermFields() {
        return [
            {
                key: 'shortTerm',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryLongTermFields() {
        return [
            {
                key: 'longTerm',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryProceduralFields() {
        return [
            {
                key: 'procedural',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryProspectiveFields() {
        return [
            {
                label: 'Familiar Situations',
                key: 'familiarSituations',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'New Situations',
                key: 'newSituations',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Deductive',
                key: 'deductive',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Abstract',
                key: 'abstract',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryCategorizationFields() {
        return [
            {
                key: 'categorization',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getMemoryJudgementFields() {
        return [
            {
                key: 'judgment',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getExecutiveFunctioningImpairmentsList() {
        return [
            {
                text: EXECUTIVE_FUNCTIONING.VERBAL_SEQUENCING,
                fields: this.getExecutiveFunctioningVerbalSequencingFields(),
                form: this.toFormGroup(this.getExecutiveFunctioningVerbalSequencingFields()),
                isCompleted: false,
            },
            {
                text: EXECUTIVE_FUNCTIONING.THOUGHT_ORGANIZATION,
                fields: this.getExecutiveFunctioningThoughtOrganizingFields(),
                form: this.toFormGroup(this.getExecutiveFunctioningThoughtOrganizingFields()),
                isCompleted: false,
            },
            {
                text: EXECUTIVE_FUNCTIONING.PLANNING,
                fields: this.getExecutiveFunctioningPlanningFields(),
                form: this.toFormGroup(this.getExecutiveFunctioningPlanningFields()),
                isCompleted: false,
            },
            {
                text: EXECUTIVE_FUNCTIONING.CALCULATIONS,
                fields: this.getExecutiveFunctioningCalculationsFields(),
                form: this.toFormGroup(this.getExecutiveFunctioningCalculationsFields()),
                isCompleted: false,
            },
        ];
    }
    getExecutiveFunctioningVerbalSequencingFields() {
        return [
            {
                key: 'verbalSequencing',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getExecutiveFunctioningThoughtOrganizingFields() {
        return [
            {
                key: 'thoughtOrganization',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getExecutiveFunctioningPlanningFields() {
        return [
            {
                key: 'planning',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getExecutiveFunctioningCalculationsFields() {
        return [
            {
                key: 'calculations',
                controlType: 'radio',
                options: [
                    '1 - WFL',
                    '2 - Minimal Impairment',
                    '3 - Moderate Impairment',
                    '4 - Severe Impairment',
                    '5 - Unable',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsImpairmentsList() {
        return [
            {
                text: PRAGMATICS.INITIATION,
                fields: this.getPragmaticsInitiationFields(),
                form: this.toFormGroup(this.getPragmaticsInitiationFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.REQUESTING,
                fields: this.getPragmaticsRequestingFields(),
                form: this.toFormGroup(this.getPragmaticsRequestingFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.TOPIC_MAINTENANCE,
                fields: this.getPragmaticsTopicMaintenanceFields(),
                form: this.toFormGroup(this.getPragmaticsTopicMaintenanceFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.TURN_TAKING,
                fields: this.getPragmaticsTurnTakingFields(),
                form: this.toFormGroup(this.getPragmaticsTurnTakingFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.INHIBITION,
                fields: this.getPragmaticsInhibitionFields(),
                form: this.toFormGroup(this.getPragmaticsInhibitionFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.SELF_MONITORING,
                fields: this.getPragmaticsSelfMonitoringFields(),
                form: this.toFormGroup(this.getPragmaticsSelfMonitoringFields()),
                isCompleted: false,
            },
            {
                text: PRAGMATICS.SELF_CORRECTION,
                fields: this.getPragmaticsSelfCorrectionFields(),
                form: this.toFormGroup(this.getPragmaticsSelfCorrectionFields()),
                isCompleted: false,
            },
        ];
    }
    getPragmaticsInitiationFields() {
        return [
            {
                key: 'initiation',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsRequestingFields() {
        return [
            {
                key: 'requesting',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsTopicMaintenanceFields() {
        return [
            {
                key: 'topicMaintenance',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsTurnTakingFields() {
        return [
            {
                key: 'turnTaking',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsInhibitionFields() {
        return [
            {
                key: 'inhibition',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsSelfMonitoringFields() {
        return [
            {
                key: 'selfMonitoring',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getPragmaticsSelfCorrectionFields() {
        return [
            {
                key: 'selfCorrection',
                controlType: 'radio',
                options: ['Intact', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                key: 'note',
                label: 'Note',
                controlType: 'text-area',
            },
        ];
    }
    getStandardizedCognitiveAssessmentImpairmentsList() {
        return [
            {
                text: STANDARDIZED_COGNITIVE_ASSESSMENTS.ORIENTATION,
                fields: this.getCognitionOrientationFields(),
                form: this.toFormGroup(this.getCognitionOrientationFields()),
                isCompleted: false,
            },
            {
                text: STANDARDIZED_COGNITIVE_ASSESSMENTS.SEQUENCING,
                fields: this.getCognitionSequencingFields(),
                form: this.toFormGroup(this.getCognitionSequencingFields()),
                isCompleted: false,
            },
            {
                text: STANDARDIZED_COGNITIVE_ASSESSMENTS.PROBLEM_SOLVING,
                fields: this.getCognitionProblemSolvingFields(),
                form: this.toFormGroup(this.getCognitionProblemSolvingFields()),
                isCompleted: false,
            },
            {
                text: STANDARDIZED_COGNITIVE_ASSESSMENTS.SAFETY_AWARENESS,
                fields: this.getCognitionSafetyAwarenessFields(),
                form: this.toFormGroup(this.getCognitionSafetyAwarenessFields()),
                isCompleted: false,
            },
        ];
    }
    getStandardizedCognitiveAssessmentOrientationFields() {
        return [
            {
                key: 'orientation',
                controlType: 'checkbox',
                options: [
                    { text: 'Person', isChecked: false },
                    { text: 'Place', isChecked: false },
                    { text: 'Time', isChecked: false },
                    { text: 'Situation', isChecked: false },
                ],
                canBeAGoal: false,
            },
        ];
    }
    getStandardizedCognitiveAssessmentSequencingFields() {
        return [
            {
                key: 'sequencing',
                controlType: 'radio',
                options: ['Intact', 'Minimally Impaired', 'Moderately Impaired', 'Severely Impaired'],
                canBeAGoal: true,
            },
        ];
    }
    getStandardizedCognitiveAssessmentProblemSolvingFields() {
        return [
            {
                key: 'problemSolving',
                controlType: 'radio',
                options: [
                    'Independent (decisions consistent/reasonable)',
                    'Modified independent (some difficulty in new situation)',
                    'Moderately impaired (decisions are poor cues and supervision required)',
                    'Severely impaired (never/rarely made decisions)',
                ],
                canBeAGoal: true,
            },
        ];
    }
    getStandardizedCognitiveAssessmentSafetyAwarenessFields() {
        return [
            {
                key: 'safetyAwareness',
                controlType: 'radio',
                options: ['Intact', 'Minimally Impaired', 'Moderately Impaired', 'Severely Impaired'],
                canBeAGoal: true,
            },
        ];
    }
    getSwallowingImpairmentsList() {
        return [
            {
                text: SWALLOWING.PRESENCE_OF_SWALLOWING_DISORDER,
                fields: this.getSwallowingPresenceofSwallowingDisorderFields(),
                form: this.toFormGroup(this.getSwallowingPresenceofSwallowingDisorderFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.RESPIRATORY_STATUS,
                fields: this.getSwallowingRespiratoryStatus(),
                form: this.toFormGroup(this.getSwallowingRespiratoryStatus()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.DENTITION,
                fields: this.getSwallowingDentitionFields(),
                form: this.toFormGroup(this.getSwallowingDentitionFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.ORAL_HYGIENE,
                fields: this.getSwallowingOralHygiene(),
                form: this.toFormGroup(this.getSwallowingOralHygiene()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.PREVIOUS_INSTRUMENTAL_SWALLOWING,
                fields: this.getSwallowingPreviousInstrumentalSwallowingAssessmentFields(),
                form: this.toFormGroup(this.getSwallowingPreviousInstrumentalSwallowingAssessmentFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.PREVIOUS_DIET_SOLIDS,
                fields: this.getSwallowingPreviousDietSolidFields(),
                form: this.toFormGroup(this.getSwallowingPreviousDietSolidFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.PREVIOUS_DIET_LIQUIDS,
                fields: this.getSwallowingPreviousDietLiquidFields(),
                form: this.toFormGroup(this.getSwallowingPreviousDietLiquidFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.CURRENT_DIET_SOLIDS,
                fields: this.getSwallowingCurrentDietSolidFields(),
                form: this.toFormGroup(this.getSwallowingCurrentDietSolidFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.CURRENT_DIET_LIQUIDS,
                fields: this.getSwallowingCurrentDietLiquidFields(),
                form: this.toFormGroup(this.getSwallowingCurrentDietLiquidFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.PREVIOUS_DIET_SOLIDS_OTHER,
                fields: this.getSwallowingPreviousDietSolidFieldsOther(),
                form: this.toFormGroup(this.getSwallowingPreviousDietSolidFieldsOther()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.PREVIOUS_DIET_LIQUIDS_OTHER,
                fields: this.getSwallowingPreviousDietLiquidFieldsOther(),
                form: this.toFormGroup(this.getSwallowingPreviousDietLiquidFieldsOther()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.CURRENT_DIET_SOLIDS_OTHER,
                fields: this.getSwallowingCurrentDietSolidFieldsOther(),
                form: this.toFormGroup(this.getSwallowingCurrentDietSolidFieldsOther()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.CURRENT_DIET_LIQUIDS_OTHER,
                fields: this.getSwallowingCurrentDietLiquidFieldsOther(),
                form: this.toFormGroup(this.getSwallowingCurrentDietLiquidFieldsOther()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.LIQUID_INTAKE_METHOD,
                fields: this.getSwallowingLiquidIntakeMethodFields(),
                form: this.toFormGroup(this.getSwallowingLiquidIntakeMethodFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.SOLID_INTAKE_METHOD,
                fields: this.getSwallowingSolidIntakeMethodFields(),
                form: this.toFormGroup(this.getSwallowingSolidIntakeMethodFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.WEIGHT,
                fields: this.getSwallowingWeightFields(),
                form: this.toFormGroup(this.getSwallowingWeightFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.SUPERVISION_PRIOR_TO_ONSET,
                fields: this.getSwallowingSupervisionFields(),
                form: this.toFormGroup(this.getSwallowingSupervisionFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.ABLE_TO_FEED_SELF,
                fields: this.getSwallowingAbleToFeedFields(),
                form: this.toFormGroup(this.getSwallowingAbleToFeedFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.POSITIONING_RELATED_TO_INTAKE,
                fields: this.getSwallowingPositioningRelatedToIntakeFields(),
                form: this.toFormGroup(this.getSwallowingPositioningRelatedToIntakeFields()),
                isCompleted: false,
            },
            {
                text: SWALLOWING.LARYNGEAL_PHARYNGEAL_EXAMINATION,
                fields: this.getSwallowingLaryngealPharyngealExaminationFields(),
                form: this.toFormGroup(this.getSwallowingLaryngealPharyngealExaminationFields()),
                isCompleted: false,
            },
        ];
    }
    getSwallowingPresenceofSwallowingDisorderFields() {
        return [
            {
                key: 'presenceOfSwallowingDisorder',
                controlType: 'checkbox',
                options: [
                    {
                        text: 'Loss of liquids/solids from mouth when eating or drinking',
                        isChecked: false,
                    },
                    {
                        text: 'Holding food in mouth/cheeks or residual food in mouth after meals',
                        isChecked: false,
                    },
                    {
                        text: 'Coughing or choking during meals or when swallowing medications',
                        isChecked: false,
                    },
                    {
                        text: 'Complains of difficulty or pain with swallowing',
                        isChecked: false,
                    },
                    { text: 'None of the above', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingRespiratoryStatus() {
        return [
            {
                key: 'respiratoryStatus',
                controlType: 'checkbox',
                options: [
                    { text: 'WFL', isChecked: false },
                    { text: 'Congested', isChecked: false },
                    { text: 'Productive cough', isChecked: false },
                    { text: 'O2 dependent', isChecked: false },
                    { text: 'Trache', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingDentitionFields() {
        return [
            {
                key: 'dentition',
                controlType: 'radio',
                options: ['WFL', 'Edentulous', 'Partials', 'Dentures', 'Missing teeth'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingOralHygiene() {
        return [
            {
                key: 'oralHygiene',
                controlType: 'radio',
                options: ['WFL', 'Thrush', 'Xerostomia'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPreviousInstrumentalSwallowingAssessmentFields() {
        return [
            {
                key: 'previousInstrumentalSwallowingAssessments',
                controlType: 'radio',
                options: ['None', 'MBSS', 'FEES'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPreviousDietSolidFields() {
        return [
            {
                key: 'previousDietSolids',
                controlType: 'radio',
                options: [
                    '7 - Regular/Easy to Chew',
                    '6 - Soft and Bite Sized',
                    '5 - Minced & Moist',
                    '4 - Pureed',
                    '3 - Liquidized',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPreviousDietLiquidFields() {
        return [
            {
                key: 'previousDietLiquids',
                controlType: 'radio',
                options: [
                    '4 - Extremely Thick',
                    '3 - Moderately Thick',
                    '2 - Mildly Thick',
                    '1 - Slightly Thick',
                    '0 - Thin',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingCurrentDietSolidFields() {
        return [
            {
                key: 'currentDietSolids',
                controlType: 'radio',
                options: [
                    '7 - Regular/Easy to Chew',
                    '6 - Soft and Bite Sized',
                    '5 - Minced & Moist',
                    '4 - Pureed',
                    '3 - Liquidized',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingCurrentDietLiquidFields() {
        return [
            {
                key: 'currentDietLiquids',
                controlType: 'radio',
                options: [
                    '4 - Extremely Thick',
                    '3 - Moderately Thick',
                    '2 - Mildly Thick',
                    '1 - Slightly Thick',
                    '0 - Thin',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPreviousDietSolidFieldsOther() {
        return [
            {
                key: 'previousDietSolidsOther',
                controlType: 'radio',
                options: [
                    '1 - Regular',
                    '2 - Mechanical Soft/Chopped Meats',
                    '3 - Mechanical Soft/Ground Meats',
                    '4 - Mechanical Soft/Pureed Meats',
                    '5 - Pureed',
                    '6 - NPO',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPreviousDietLiquidFieldsOther() {
        return [
            {
                key: 'previousDietLiquidsOther',
                controlType: 'radio',
                options: ['1 - Thin', '2 - Nectar thick', '3 - Honey thick', '4 - Pudding Thick', '5 - NPO'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingCurrentDietSolidFieldsOther() {
        return [
            {
                key: 'currentDietSolidsOther',
                controlType: 'radio',
                options: [
                    '1 - Regular',
                    '2 - Mechanical Soft/Chopped Meats',
                    '3 - Mechanical Soft, Ground Meats',
                    '4 - Mechanical Soft, Pureed Meats',
                    '5 - Pureed',
                    '6 - NPO',
                ],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingCurrentDietLiquidFieldsOther() {
        return [
            {
                key: 'currentDietLiquidsOther',
                controlType: 'radio',
                options: ['1 - Thin', '2 - Nectar Thick', '3 - Honey Thick', '4 - Pudding Thick', '5 - NPO'],
                canBeAGoal: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingLiquidIntakeMethodFields() {
        return [
            {
                key: 'liquidIntakeMethod',
                controlType: 'radio',
                options: [
                    'Successive drinking',
                    'Cup drinking',
                    'Straw drinking',
                    'Controlled amounts',
                    'Tube',
                    'PO trials with ST only',
                    'Ice chips for pleasure',
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingSolidIntakeMethodFields() {
        return [
            {
                key: 'solidIntakeMethod',
                controlType: 'radio',
                options: ['Pleasure Feeding', 'PO trials with ST only'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingWeightFields() {
        return [
            {
                key: 'weight',
                controlType: 'radio',
                options: ['WFL', 'Loss', 'Gain', 'Fluctuates'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingSupervisionFields() {
        return [
            {
                key: 'supervisionPriorToOnset',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingAbleToFeedFields() {
        return [
            {
                key: 'able to feed self?',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingPositioningRelatedToIntakeFields() {
        return [
            {
                key: 'positioningRelatedToIntake',
                controlType: 'radio',
                options: ['Adequate', 'Inadequate'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Note',
                key: 'note',
                controlType: 'text-area',
            },
        ];
    }
    getSwallowingLaryngealPharyngealExaminationFields() {
        return [
            {
                label: 'Gag Reflex',
                key: 'gagReflex',
                controlType: 'radio',
                options: ['Present', 'Absent'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Volitional Cough',
                key: 'volitionalCough',
                controlType: 'radio',
                options: ['Adequate', 'Inadequate'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Dry Swallow',
                key: 'drySwallow',
                controlType: 'radio',
                options: ['Adequate', 'Inadequate'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Vocal Quality',
                key: 'vocalQuality',
                controlType: 'radio',
                options: ['WFL', 'Impaired'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Esophageal Symptoms Present?',
                key: 'esophageal symptoms present?',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Instrumental Swallowing Assessment Indicated?',
                key: 'instrumental swallowing assessment indicated?',
                controlType: 'radio',
                options: ['Yes', 'No'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Diet Recommendations - Solids',
                key: 'dietRecommendationSolids',
                controlType: 'radio',
                options: ['Regular/Easy to Chew', 'Soft and Bite Sized', 'Minced & Moist', 'Pureed', 'Liquidized'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Diet Recommendations - Liquids',
                key: 'dietRecommendationLiquids',
                controlType: 'radio',
                options: ['Extremely Thick', 'Moderately Thick', 'Mildly Thick', 'Slightly Thick', 'Thin'],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Solid Textures Tested',
                key: 'solidTexturesTested',
                controlType: 'checkbox',
                options: [
                    { text: 'Regular/Easy to Chew', isChecked: false },
                    { text: 'Soft & Bite Sized', isChecked: false },
                    { text: 'Minced & Moist', isChecked: false },
                    { text: 'Pureed', isChecked: false },
                    { text: 'Liquidized', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Liquid Textures Tested',
                key: 'liquidTexturesTested',
                controlType: 'checkbox',
                options: [
                    { text: 'Extremely Thick', isChecked: false },
                    { text: 'Moderately Thick', isChecked: false },
                    { text: 'Mildly Thick', isChecked: false },
                    { text: 'Slightly Thick', isChecked: false },
                    { text: 'Thin', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Oral/Pharyngeal Performance',
                key: 'oralPharyngealPerformance',
                controlType: 'checkbox',
                options: [
                    { text: 'WFl', isChecked: false },
                    { text: 'Anterior spillage', isChecked: false },
                    { text: 'Inadequate rotary chew pattern', isChecked: false },
                    { text: 'Incomplete bolus formation', isChecked: false },
                    { text: 'Orally defensive', isChecked: false },
                    { text: 'Oral residue', isChecked: false },
                    { text: 'Piecemeal Swallow', isChecked: false },
                    { text: 'Pocketing bilaterally', isChecked: false },
                    { text: 'Pocketing on left', isChecked: false },
                    { text: 'Pocketing on right', isChecked: false },
                    { text: 'Shortness of breath', isChecked: false },
                    { text: 'Suspected premature spillage', isChecked: false },
                    {
                        text: 'Decreased activity tolerance to complete meal',
                        isChecked: false,
                    },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Laryngeal/Pharyngeal Performance',
                key: 'laryngealPharyngealPerformance',
                controlType: 'checkbox',
                options: [
                    { text: 'WFL', isChecked: false },
                    { text: 'Wet vocal quality', isChecked: false },
                    { text: 'Watery eyes', isChecked: false },
                    { text: 'Watery Nose', isChecked: false },
                    { text: 'Shortness of breath', isChecked: false },
                    { text: 'Delayed swallow initiation', isChecked: false },
                    { text: 'Throat clearing after swallow', isChecked: false },
                    { text: 'Coughing during swallow', isChecked: false },
                    { text: 'Coughing after swallow', isChecked: false },
                    { text: 'Choking', isChecked: false },
                    { text: 'Poor attention to task', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Compensatory Swallowing Strategies Recommended',
                key: 'compensatorySwallowingStrategies',
                controlType: 'checkbox',
                options: [
                    { text: 'Upright for po intake', isChecked: false },
                    { text: 'Alternate solids/liquids', isChecked: false },
                    { text: 'Alternate tastes', isChecked: false },
                    { text: 'Alternate temperatures', isChecked: false },
                    { text: 'Decrease bolus size', isChecked: false },
                    { text: 'Decrease rate', isChecked: false },
                    { text: 'Double swallow', isChecked: false },
                    { text: 'Chin tuck', isChecked: false },
                    { text: 'Effortful swallow', isChecked: false },
                    { text: 'Hard throat clear/re-swallow', isChecked: false },
                    { text: 'Lingual sweep', isChecked: false },
                    { text: 'Supraglottic swallow', isChecked: false },
                    { text: 'Turn head to affected side', isChecked: false },
                    { text: 'Turn head to unaffected side', isChecked: false },
                ],
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Standardized Swallowing Assessment',
                key: 'standardizedSwallowingAssessment',
                controlType: 'text-area',
                canBeAGoal: true,
                isRegularAlike: true,
            },
            {
                label: 'Additional Analysis',
                key: 'additionalAnalysis',
                controlType: 'text-area',
                canBeAGoal: true,
                isRegularAlike: true,
            },
        ];
    }

    toFormGroup(fields: DynamicFields[]) {
        const group: any = {};

        fields.forEach((field) => {
            group[field.key] = field.required
                ? new FormControl(field.value || '', [
                      Validators.required,
                      Validators.min(field.minValue),
                      Validators.max(field.maxValue),
                  ])
                : new FormControl(field.value || '', [Validators.min(field.minValue), Validators.max(field.maxValue)]);
        });
        return new FormGroup(group);
    }
    toRangeOfMotionForm(fields: DynamicFields[]) {
        // const group: any = {};

        // fields.forEach((field) => {
        //     const innerGroup: any = {};
        //     group[field.key] = field.required
        //         ? new FormControl(field.value || '', [
        //               Validators.required,
        //               Validators.min(field.minValue),
        //               Validators.max(field.maxValue),
        //           ])
        //         : new FormControl(field.value || '', [Validators.min(field.minValue), Validators.max(field.maxValue)]);

        //     innerGroup['note'] = new FormGroup({
        //         [field.note.key]: new FormControl(field.note.value || '')
        //     });

        //     group[field.key] = new FormGroup(innerGroup);
        // });
        // return new FormGroup(group);
        const group: { [key: string]: FormControl | FormGroup } = {};

        fields.forEach((field) => {
            group[field.key] = new FormControl(
                field.value || '',
                [
                    field.required ? Validators.required : null,
                    Validators.min(field.minValue),
                    Validators.max(field.maxValue),
                ].filter((validator) => validator !== null)
            );
            if (field.note) {
                group[field.note.key] = new FormControl(field.note.value || '');
            } else if (field.key == 'strengthNote') {
                group[field.key] = new FormControl(field.value || '');
            }
        });
        return new FormGroup(group);
    }
    toNestedFormGroup(fields) {
        const group: any = {
            left: {},
            right: {},
        };

        fields.forEach((field) => {
            if (field.side === 'LHS') {
                group.left[field.key] = field.required
                    ? new FormControl(field.value || '', Validators.required)
                    : new FormControl(field.value || '');
            } else if (field.side === 'RHS') {
                group.right[field.key] = field.required
                    ? new FormControl(field.value || '', Validators.required)
                    : new FormControl(field.value || '');
            } else {
                group.note = field.required
                    ? new FormControl(field.value || '', Validators.required)
                    : new FormControl(field.value || '');
            }
        });
        group.left = new FormGroup(group.left);
        group.right = new FormGroup(group.right);
        return new FormGroup(group);
    }

    markCompletedItems(filteredListItems, currentAdmission) {
        filteredListItems.map((fli) => {
            if (currentAdmission) {
                const found = this.patientAssessmentService
                    .getAssessmentPath(currentAdmission, 'impairments')
                    ?.findIndex((val) => val.impairmentName === fli.text);
                if (found !== -1) return (fli.isCompleted = true);
            }
        });
    }

    // Map form fields with original key labels and format to be displayed in grid
    mapFieldNameToResult(result, fields, mappedResult: any[], section?, note?) {
        Object.keys(result).forEach((val) => {
            if (typeof result[val] === 'object' && !Array.isArray(result[val]) && result[val] !== null) {
                this.mapFieldNameToResult(result[val], fields, mappedResult, val, result.note);
            } else {
                if (result[val] && val !== 'note') {
                    const found = fields.find((x) => x.key === val);
                    if (found)
                        mappedResult.push({
                            label: found.label,
                            key: found.key,
                            value: result[val],
                            note: note ?? result.note,
                            section,
                            canBeAGoal: found.canBeAGoal,
                            scale: this.mapScale(found),
                        });
                }
            }
        });
        return mappedResult;
    }

    mapScale(item: any) {
        if (item.isRegularAlike) {
            //map scale for regularAlike goal
            return {
                scaleType: 'input',
                assessmentType: 'impairments',
                scaleDetails: { name: 'custom', scaleInterpretation: 'freeText' },
            };
        } else {
            return {
                scaleType: item.controlType,
                assessmentType: 'impairments',
                scaleDetails: {
                    name: null,
                    placeholder: item.placeholder,
                    minValue: item.minValue,
                    maxValue: item.maxValue,
                    options: item.options,
                    scaleInterpretation: item.scaleInterpretation,
                },
            };
        }
    }
}
