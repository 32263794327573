// colworx-ak start
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Staff, StaffFacility } from '@app/interfaces';
import { environment } from '@environments/environment';
import { patientFilters, User, USER_TYPES, UserApiResponse } from '@app/models';
import { Store } from '@ngrx/store';
import { staffChangedAction } from '@app/store';
import { StaffState } from '@app/store/store.interface';
import { StoreService } from './store.service';
import { ROUTER_UTILS } from '@app/helpers';
import { GoBackComponent } from '@app/shared/go-back/go-back.component';
import { getCurrentUser } from '@app/helpers/constants/documentation-report';
import { ToasterService } from './toaster.service';
import { ADD_USER_TABS } from '@app/modules/admin/user-management/constants';
import { cloneDeep } from 'lodash';
import { AuthService } from './auth.service';
import { FormGroup } from '@angular/forms';

const baseUrl = `${environment.apiUrl}/physician-staff`;
@Injectable({ providedIn: 'root' })
export class PPUserManagementService {
    userPersonalInfo = new BehaviorSubject({});
    userFacilityRoles = new BehaviorSubject([]);
    isSameRoleForAllFacilities = new BehaviorSubject(false);
    userAdditionalInfo = new BehaviorSubject({});
    physicianAdditionalInfo = new BehaviorSubject({});
    userType = new BehaviorSubject(USER_TYPES.NONE);
    isConfirmedPhysician = new BehaviorSubject(false);
    addUserTabs = new BehaviorSubject(cloneDeep(ADD_USER_TABS));
    isSubmitting = new BehaviorSubject(false);
    isFormValid: FormGroup;

    private staffSubject: BehaviorSubject<Staff>;
    public _patient: Observable<Staff>;
    user: User;

    private patientFilters$: BehaviorSubject<patientFilters>;

    // needed to find past facility admission to limit new admit date restriction
    searchPatientResponse: [] = null;
    selectedPatientMRN = null;
    selectedPatientFacility = null;

    tab$: Observable<any>;
    constructor(
        private goBackComponent: GoBackComponent,
        private router: Router,
        private http: HttpClient,
        private toasterService: ToasterService,
        private store: Store<{ staff: StaffState }>,
        private storeService: StoreService,
        public authService: AuthService
    ) {
        this.user = getCurrentUser();

        this.staffSubject = new BehaviorSubject<Staff>(null);
        this._patient = this.staffSubject.asObservable();
        // this.tab$ = store.pipe(select('patient'));
    }

    public get patientValue(): Staff {
        return this.staffSubject.value;
    }

    set patientFilters(val: Observable<patientFilters>) {
        val.subscribe((data) => {
            this.patientFilters$.next(data);
        });
    }

    get patientFilters(): Observable<patientFilters> {
        return this.patientFilters$.asObservable();
    }
    initializeAllFormStates() {
        this.userAdditionalInfo.next({});
        this.physicianAdditionalInfo.next({});
        this.userPersonalInfo.next({});
        this.userFacilityRoles.next([]);
        this.isSameRoleForAllFacilities.next(false);
        this.userType.next(USER_TYPES.NONE);
        this.isConfirmedPhysician.next(false);
        this.addUserTabs.next(cloneDeep(ADD_USER_TABS));
        this.isSubmitting.next(false);
    }
    userPersonalInformationSave(staffData: Staff) {
        // Dispatch action to update the store with the new staff data
        this.store.dispatch(staffChangedAction({ staff: staffData }));

        // Update the stepper to the next route
        this.storeService.admissionStepper = ROUTER_UTILS.config.physicianAccountsAndDocument.facilityAdd;

        // Notify subscribers of the new staff data
        this.staffSubject.next(staffData);

        // Return the staff data
        return staffData;
    }
    


    registerStaff(staffData: Staff) {
        return this.http.post<any>(`${baseUrl}/registerStaff`, staffData, { withCredentials: true }).pipe(
            map((staff) => {
                this.store.dispatch(staffChangedAction({ staff: staff.data }));
                this.storeService.admissionStepper = ROUTER_UTILS.config.physicianAccountsAndDocument.facilityAdd;
                this.staffSubject.next(staff.data);
                
                return staff.data;
            })
        );
    }
    assignFacilitySave(staffFacilityData: StaffFacility, id: string) {
        return this.http.put<any>(`${baseUrl}/assignFacility/${id}`, staffFacilityData, { withCredentials: true }).pipe(
            map((assignFacility) => {
                this.staffSubject.next(assignFacility.data);
                this.storeService.resetStaffState();
                this.toasterService.show({ title: 'Success', body: 'User added', type: 'success' });
                // this.goBackComponent.backButtonAction();
                this.router.navigateByUrl(
                    ROUTER_UTILS.config.physicianAccountsAndDocument.root +
                    '/' +
                    ROUTER_UTILS.config.physicianAccountsAndDocument.account +
                    '?tab=invited'
                );
                return assignFacility.data;
            })
        );
    }
    // colworx-ak add getAllStaff
    getAllStaff(physicianId: string) {
        return this.http.get<UserApiResponse>(`${baseUrl}/${physicianId}`);
    }

    getStaffUserById(physicianId: string, userId: string) {
        return this.http.get<UserApiResponse>(`${baseUrl}/${physicianId}/${userId}`);
    }
    // colworx-ak add terminateUserStaff
    terminateUserStaff(id: string) {
        return this.http.put(`${baseUrl}/userTerminated/${id}`, {
            withCredentials: true,
        });
    }
    // colworx-ak add resendInviteUserStaff
    resendInviteUserStaff(id: string) {
        return this.http.put(`${baseUrl}/resendInvite/${id}`, {
            withCredentials: true,
        });
    }
    // colworx-ak add cancelInviteUserStaff
    cancelInviteUserStaff(id: string) {
        return this.http.put(`${baseUrl}/cancelInvite/${id}`, {
            withCredentials: true,
        });
    }

    UserPersonalInfoUpdate(staffData: Staff, userId: string) {
        if (!userId) {
            this.storeService
                .getStaffState()
                .pipe(take(1))
                .subscribe((data) => {
                    userId = data.id;
                });
        }
        return this.http.put<any>(`${baseUrl}/updateStaff/${userId}`, staffData, { withCredentials: true }).pipe(
            map((staff) => {
                const tempP: StaffState = { id: staff.data.user._id, ...staffData, ...staff };
                // this.store.dispatch(staffChangedAction({ staff: staff.data }));
                this.store.dispatch(staffChangedAction({ staff: tempP }));
                this.staffSubject.next(staff.data);
                return staff.data;
            })
        );
    }

    // colworx-sk
    getEValuationForm(docId: string) {
        return this.http.get<UserApiResponse>(`${environment.apiUrl}/evaluation/poc/${docId}`);
    }

    getUpocForm(docId: string) {
        return this.http.get<UserApiResponse>(`${environment.apiUrl}/upoc/poc/${docId}`);
    }

    getRecertificationForm(docId: string) {
        return this.http.get<UserApiResponse>(`${environment.apiUrl}/recertification/poc/${docId}`);
    }

    UpdatePoc(obj: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/document-detail/update-poc`, obj);
    }

    //Edit history
    getEvaluationHistory(docId: string) {
        return this.http.get<UserApiResponse>(`${environment.apiUrl}/document-detail/poc-document-history/${docId}`);
    }

    // colworx-sk

    // colworx-ak add getAllDocumentsByPhysician
    getAllDocumentsByPhysician(physicianId: string, selectedFacility: string) {
        if (this.authService.isPhysicianStaff) {
            const user = JSON.parse(localStorage.getItem('innova-frontend'))?.user;
            return this.http.get<UserApiResponse>(
                `${environment.apiUrl}/document-detail/poc-documents-by-physician/${selectedFacility}/${physicianId}/${user?.id}`
            );
        } else {
            return this.http.get<UserApiResponse>(
                `${environment.apiUrl}/document-detail/poc-documents-by-physician/${selectedFacility}/${physicianId}`
            );
        }
    }

    //home page
    getPhysicianPocCount(physicianId: string, selectedFacility: string) {
        if (this.authService.isPhysicianStaff) {
            const user = JSON.parse(localStorage.getItem('innova-frontend'))?.user;
            return this.http.get<UserApiResponse>(
                `${environment.apiUrl}/dashboard/poc-doc-count-by-physician/${selectedFacility}/${physicianId}/${user?.id}`
            );
        } else {
            return this.http.get<UserApiResponse>(
                `${environment.apiUrl}/dashboard/poc-doc-count-by-physician/${selectedFacility}/${physicianId}`
            );
        }
       
    }
}
// colworx-ak end
