<div class="time-block" [class.disabled]="isSelected">
    <div class="comment-box-wrapper" [class.active]="isSelected">
        <div class="icon-box">
            <span *ngIf="timeBlockIcon" class="material-symbols-outlined"> schedule </span>
            <span *ngIf="commentIcon" class="material-symbols-outlined"> chat </span>
        </div>
        <div
            container="body"
            class="comment-box-inner text-secondary"
            #note
        >
            <div class="px-2 py-3">
                <div class="d-flex justify-content-between">
                    <div *ngIf="timeBlockIcon" class="text-secondary body-medium-semibold">Blocked Time</div>
                    <div *ngIf="commentIcon" class="text-secondary body-medium-semibold">Therapist Comments</div>
                    <div class="d-flex">
                        <div *ngIf="startTime && endTime; else showMinutes" class="text-secondary body-medium-semibold font-weight-500">
                            {{startTime | date: 'h : mm a'}} - {{endTime | date: 'h : mm a'}}
                        </div>
                        <ng-template #showMinutes>
                            <div *ngIf="timeBlockIcon" class="text-secondary body-medium-semibold font-weight-500">
                                {{minutes | minutesToHoursMinutes}}
                            </div>
                        </ng-template>
                        <div class="edit-timeBlock dropdown-menu-end" *ngIf="!(caseloadDate | isPastDate) && editTimeBlock">
                            <div ngbDropdown class="d-inline-block">
                                <button
                                class="dropdown-toggle innova-btn btn-sm d-flex flex-row justify-content-center align-items-center p-0"
                                    id="dd-toggle"
                                    [placement]="placement"
                                    ngbDropdownToggle
                                >
                                    <span class="material-symbols-outlined font-18 bold-icon"> more_vert </span>
                                    <!-- <span class="mr-2">Options</span> -->
                                </button>
                                <!-- *ngIf="!disableCheck()" -->
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dd-small">
                                    <button
                                        ngbDropdownItem
                                        class="nav-item nav-link"
                                        (click)="editBlock()"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        ngbDropdownItem
                                        class="nav-item nav-link"
                                        (click)="deleteBlock()"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    [ngbTooltip]=" description.length > 35 ? description : ''"
                    [placement]="placement"
                    tooltipClass="comments-tooltip"
                    class="body-medium d-block overflow-hidden text-ellipsis text-secondary whitespace-nowrap"> 
                    {{ description ? description : 'No note' }}
                </div>
            </div>
            
        </div>
    </div>
</div>
