import { Injectable } from '@angular/core';
import { Therapist } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';
import { environment } from '@environments/environment';
import { FacilityManagementService } from './facilityManagement.service';

const baseUrl = environment.apiUrl + '/therapist';
const baseUrlAvailability = environment.apiUrl + '/availability/therapist';
const availabilityUrl = environment.apiUrl + '/availability'

@Injectable({
    providedIn: 'root',
})
export class TherapistService {
    constructor(private http: HttpClient, private facilityManagementService: FacilityManagementService) {}

    getTherapist(id: string): Observable<Therapist> {
        return this.http.get<any>(`${baseUrl}/${id}`).pipe(map((v) => v.data));
    }

    getTherapistDocCount(id: string): Observable<any> {
        return this.http.get<any>(`${availabilityUrl}/get-doc-count/${id}`)
    }

    getAllTherapists(): Observable<Therapist[]> {
        return this.http
            .get<any>(`${baseUrl}/all/` + this.facilityManagementService._currentFacility.id, { withCredentials: true })
            .pipe(map((v) => v.data));
    }

    getAllActiveTherapists(discipline = null): Observable<Therapist[]> {
        const url = discipline
            ? `${baseUrl}/all-active/` +
              this.facilityManagementService._currentFacility.id +
              '?discipline=' +
              discipline
            : `${baseUrl}/all-active/` + this.facilityManagementService._currentFacility.id;
        return this.http
            .get<any>(url, {
                withCredentials: true,
            })
            .pipe(map((v) => v.data));
    }
    getTherapistAvailability(date: string) {
        return this.http.post<any>(`${baseUrlAvailability}/white-board`, { date }).pipe(take(1));
    }
}
