import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, first, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { getWeekEndDate, getWeekStartDate } from '@app/helpers/utils';
import { filterNotesByDateRange } from '@app/helpers/utils/data.utils';
import { StoreService } from './store.service';

const baseUrl = `${environment.apiUrl}`;
@Injectable({
    providedIn: 'root',
})
export class SchedulerV2Service {
    patientTabChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(private http: HttpClient, private store: Store, private storeService: StoreService) {}

    getSchedulerData(facilityId, data) {
        return this.http.post(
            `${baseUrl}/scheduler/planner-weekly/${facilityId}`,
            { date: data },
            {
                withCredentials: true,
            }
        );
    }
    autoScheduler(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/auto/${facilityId}`, data, {
            withCredentials: true,
        });
    }
    getManageLaborData(facilityId, data) {
        return this.http
            .post(
                `${baseUrl}/therapist/weekly/${facilityId}`,
                { date: data },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((value: any) => {
                    // @TODO: This is a temporary hack, it will not be needed when new data is added after cleaning
                    const therapistList = [];
                    value.data.therapists.forEach((t: any) => {
                        if (t.user._id) therapistList.push(t);
                    });
                    value.data.therapists = therapistList;
                    return value;
                })
            );
    }
    getAllTherapistsFromCurrentDate(facilityId, data) {
        return this.http
            .post(
                `${baseUrl}/availability/all-therapists/${facilityId}`,
                { date: data },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((value: any) => {
                    // @TODO: This is a temporary hack, it will not be needed when new data is added after cleaning
                    const therapistList = [];
                    value.data.therapists.forEach((t: any) => {
                        if (t.user._id) therapistList.push(t);
                    });
                    value.data.therapists = therapistList;
                    return value;
                })
            );
    }
    create(data) {
        return this.http.post(`${baseUrl}/caseload/`, data, {
            withCredentials: true,
        });
    }
    update(data) {
        return this.http.post(`${baseUrl}/caseload/${data.id}`, data, {
            withCredentials: true,
        });
    }
    mass_update(data) {
        return this.http.put(`${baseUrl}/caseload/mass-un-assign`, data, {
            withCredentials: true,
        });
    }
    createOrUpdate(data) {
        return this.http.post(`${baseUrl}/caseload/updateMany`, data, {
            withCredentials: true,
        });
    }
    getCaseLoadDataById(caseLoadId, facilityId, date) {
        return this.http.post<any>(
            `${baseUrl}/caseload/${caseLoadId}/${facilityId}`,
            { date },
            {
                withCredentials: true,
            }
        );
    }
    updatePlanDay(data) {
        return this.http.patch(`${baseUrl}/scheduler/update/plan-day`, data, {
            withCredentials: true,
        });
    }
    getPlannedDayOfPlannedTherapy(id) {
        return this.http.get<any>(`${baseUrl}/plan-therapy/${id}/plannedDays`, {
            withCredentials: true,
        });
    }
    unAssignUpdate(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/undo-assignment/${facilityId}/${data.caseload}`, data, {
            withCredentials: true,
        });
    }
    updateAppointment(caseLoadId, facilityId, data) {
        return this.http.post(`${baseUrl}/caseload/bulk-appointments/${caseLoadId}/${facilityId}`, data, {
            withCredentials: true,
        });
    }
    reAssignUpdate(facilityId, data) {
        return this.http.post(`${baseUrl}/scheduler/redo-assignment/${facilityId}/${data.caseload}`, data, {
            withCredentials: true,
        });
    }
    caseloadPrint(data) {
        return this.http.post(`${baseUrl}/caseload/print`, data, {
            withCredentials: true,
        });
    }
    patientSessionUpdated(facilityId, data) {
        this.storeService.setLoadingState(true);
        this.getSchedulerData(facilityId, getWeekStartDate(new Date(data.date)))
            .pipe(first())
            .subscribe((result: any) => {
                this.storeService.setSchedulerCalendar(new Date(data.date));
                result.data.patients = filterNotesByDateRange(
                    result.data.patients,
                    getWeekStartDate(new Date(data.date)),
                    getWeekEndDate(new Date(data.date))
                );

                this.storeService.setScheduler(result.data);
                this.storeService.setLoadingState(false);
            });
    }
    updateTherapistAvailability(data: any) {
        return this.http.post(
            `${baseUrl}/availability/updateMany`,
            { availabilities: data },
            {
                withCredentials: true,
            }
        );
    }

    getTotalBlockedMinutes(allTimeBlocks: any[]): number {
        return allTimeBlocks.reduce((total, timeBlock) => {
            return total + (timeBlock.plannedTx || 0);
        }, 0);
    }
}
