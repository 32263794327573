<!-- <div class="therapist" *ngFor="let therapist of therapistDetails">
    <div style="display: flex">
        <div>
            <h5 class="text-secondary">{{ therapist.therapistName }}</h5>
        </div>
        <div style="margin-left: auto">
            <h6 class="mb-0 mt-1 text-secondary">
                <span style="vertical-align: middle">{{ scheduleDate | date: 'EEEE, MMMM d, y' }}</span>
            </h6>
        </div>
    </div>
    <div class="mt-2" style="height: 600px; width: 1000px">
        <app-data-grid
            [columnDefs]="columnDefs"
            [rowData]="therapist.patientDetails"
            [rowClassRules]="null"
        ></app-data-grid>
    </div>
</div> -->
<div
    *ngFor="let therapist of therapists"
    style="
        width: 100%;
        margin: 0 auto;
        color: var(--black, #101820);
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
    "
    class="theripst-report-wrapper"
>
    <div
        style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 24px;
            border-bottom: 1px solid #101820;
        "
    >
        <div><img width="79px" height="24px" src="../../../../assets/logo/logo.svg" /></div>
        <div style="display: flex">
            <div style="margin-right: 25px">
                <div style="margin-bottom: 10px" class="info-text">
                    <strong>Therapist:</strong> {{ therapist.name | titlecase }}
                </div>
                <div class="info-text">
                    <strong>Date:</strong> {{ dateLabel ? dateLabel : (therapist.caseload.day | date: 'MM/dd/yyyy') }}
                </div>
            </div>
            <div>
                <div style="margin-bottom: 10px" class="info-text">
                    <strong>Facility:</strong> {{ therapist.currentFacility.facilityName }}
                </div>
                <div class="info-text"><strong>Total Patient: </strong>{{ therapist.noOfPatients }}</div>
            </div>
        </div>
    </div>
    <div style="padding: 12px 0 20px 32px">
        <div>
            <div
                *ngIf="therapist.timeBlock?.exists"
                class="notes"
                style="width: 100%; display: inline-block; vertical-align: top"
            >
                <span
                    ><strong>{{ therapist.timeBlock.minutes | minutesToHours }} Time Block </strong></span
                >
                <span>{{ therapist.timeBlock.reason }}</span>
                <hr />
            </div>
            <div
                *ngIf="therapist.caseload.comment"
                class="notes"
                style="width: 100%; display: inline-block; vertical-align: top"
            >
                <strong>Note: </strong>
                <span>{{ therapist.caseload.comment }}</span>
                <hr />
            </div>
            <div
                class="print-box"
                *ngFor="let patientNote of therapist.caseload.patientNotes"
                style="margin-right: 30px; margin-top: 20px; width: 100%; display: inline-block; vertical-align: top"
            >
                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #000;
                        padding-bottom: 5px;
                    "
                >
                    <div>
                        <strong>{{
                            patientNote.docId.facilityAdmission.patient.lastName +
                                ' ' +
                                patientNote.docId.facilityAdmission.patient.firstName
                                | titlecase
                                | name
                        }}</strong>
                    </div>
                    <div><strong>Type:</strong> {{ patientNote.noteType }}</div>
                    <div><strong>Room:</strong> {{ patientNote.docId.facilityAdmission.roomNumber }}</div>
                    <div>
                        <strong>Appt:</strong>
                        {{
                            patientNote?.docId?.appointment?.startTime
                                ? (patientNote?.docId?.appointment?.startTime | date: 'h : mm a')
                                : '--'
                        }}
                        -
                        {{
                            patientNote?.docId?.appointment?.endTime
                                ? (patientNote?.docId?.appointment?.endTime | date: 'h : mm a')
                                : '--'
                        }}
                    </div>
                    <div><strong>Next PN:</strong> {{ patientNote.docId.nextPN | date: 'MM/dd/yyyy' }}</div>
                    <div>
                        <span style="font-weight: 500">Planned Min: </span>
                        <span>
                            <span class="font-weight-bold mr-2">{{ patientNote?.docId?.isSplit ? patientNote?.docId?.splitTx: patientNote?.docId?.plannedTx || 0 }} mins </span>
                            <span *ngIf="patientNote.noteType === 'Daily Note'">
                                <span *ngIf="patientNote?.docId?.individual?.planned > 0">
                                    {{ patientNote?.docId?.individual?.planned
                                    }}<span class="font-weight-bold">I</span>
                                </span>
                                <span *ngIf="patientNote?.docId?.concurrent?.planned > 0">
                                    {{ patientNote?.docId?.concurrent?.planned
                                    }}<span class="font-weight-bold">C</span>
                                </span>
                                <span *ngIf="patientNote?.docId?.group?.planned > 0">
                                    {{ patientNote?.docId?.group?.planned
                                    }}<span class="font-weight-bold">G</span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style="margin-right: 25px">
                        <span style="font-weight: 500">Payor:</span>
                        {{ patientNote.docId.facilityAdmission.payor }}
                    </div>
                    <div
                        *ngIf="patientNote.docId?.isSplit"
                        style="width: 60px"
                        class="border border-1 rounded-pill px-2 border-dark"
                    >
                        <span class="d-inline-block align-middle material-symbols-outlined font-12 mr-1">
                            alt_route
                        </span>
                        <span class="d-inline-block align-middle">Split</span>
                    </div>
                </div>
                <div style="margin-top: 18px; margin-bottom: 13px">
                    <div style="display: inline-block; width: 50%" *ngFor="let cptCode of patientNote.docId.cptCodes">
                        <ul
                            style="
                                padding-left: 0;
                                margin: 0;
                                margin-bottom: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <li style="list-style: none; display: inline-block; width: 20%">{{ cptCode?.code }}</li>
                            <li
                                style="
                                    list-style: none;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    display: inline-block;
                                    width: 50%;
                                "
                            >
                                {{ cptCode?.description }}
                            </li>
                            <li
                                *ngIf="cptCode?.code && cptCode?.description"
                                style="list-style: none; width: 30%; display: inline-block"
                            >
                                _________
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="patientNote.docId.appointment?.notes" style="display: inline-block; width: 100%">
                        <strong>Note: </strong>{{ patientNote.docId.appointment?.notes }}
                    </div>
                    <div
                        style="display: inline-block; width: 100%; margin-bottom: 10px; margin-top: 10px"
                        *ngIf="patientNote.noteType !== 'Discharge Note'"
                    >
                        <strong>Contraindications:</strong>
                        <span
                            *ngIf="
                                patientNote.docId.contraindications &&
                                patientNote.docId.contraindications.length > 0 &&
                                checkDescriptionExists(patientNote.docId.contraindications)
                            "
                        >
                            <span *ngFor="let contraindication of patientNote.docId.contraindications; index as i"
                                ><strong
                                    *ngIf="contraindication.description"
                                    style="margin-left: 5px; margin-right: 5px"
                                    >{{ i + 1 }})</strong
                                ><span *ngIf="contraindication.description">{{
                                    contraindication.description
                                }}</span></span
                            >
                        </span>
                        <span
                            *ngIf="
                                !patientNote.docId.contraindications ||
                                patientNote.docId.contraindications.length == 0 ||
                                !checkDescriptionExists(patientNote.docId.contraindications)
                            "
                        >
                            N/A</span
                        >
                    </div>
                    <div
                        style="display: inline-block; width: 100%; margin-bottom: 10px"
                        *ngIf="patientNote.noteType !== 'Discharge Note'"
                    >
                        <strong>Precautions:</strong>
                        <span
                            *ngIf="
                                patientNote.docId.precautions &&
                                patientNote.docId.precautions.length > 0 &&
                                checkDescriptionExists(patientNote.docId.precautions)
                            "
                        >
                            <span *ngFor="let precaution of patientNote.docId.precautions; index as i"
                                ><strong *ngIf="precaution.description" style="margin-left: 5px; margin-right: 5px"
                                    >{{ i + 1 }})</strong
                                ><span *ngIf="precaution.description">{{ precaution.description }}</span></span
                            >
                        </span>
                        <span
                            *ngIf="
                                !patientNote.docId.precautions ||
                                patientNote.docId.precautions.length == 0 ||
                                !checkDescriptionExists(patientNote.docId.precautions)
                            "
                        >
                            N/A</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
