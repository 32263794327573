<ng-container *ngIf="(loadingObs | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div
        class="mrn-patient d-flex align-items-center"
        #mrnBar
        id="mrn-bar"
        [ngStyle]="{ transform: widthToTransform ?? isSticky }"
        [ngClass]="{ 'mt-3': !isSticky }"
    >
        <div class="d-flex justify-content-center align-items-center mrn-patient-name-wrapper">
            <span *ngIf="isChevron" class="material-symbols-outlined solid chevron"> chevron_left </span>
            <div class="d-flex flex-row align-self-center align-items-center">
                <app-go-back
                    mainClass="mt-2 text-secondary mr-4"
                    [path]="rootPath"
                    [pathParams]="pathParams"
                    *ngIf="isSticky"
                ></app-go-back>
                <h6
                    class="patient-initial-mrn-bar h6-semibold bg-primary text-white d-flex flex-row justify-content-center align-items-center rounded-circle m-0 flex-shrink-0"
                >
                    <span> {{ (patient$ | async).lastName?.charAt(0) | titlecase }}</span>
                    <span style="margin-left: 1px"> {{ (patient$ | async).firstName?.charAt(0) | titlecase }}</span>
                </h6>
                <h6
                    class="h6-semibold mb-0 ml-2 overflow-hidden text-ellipsis text-secondary whitespace-nowrap"
                    style="max-width: 70px"
                    placement="bottom"
                    [ngbTooltip]="
                        (patient$ | async)?.lastName && (patient$ | async).lastName.length >= 7
                            ? (patient$ | async).lastName
                            : ''
                    "
                >
                    {{ (patient$ | async)?.lastName | titlecase }},
                </h6>
                <h6
                    class="h6-semibold mb-0 ml-2 overflow-hidden text-ellipsis text-secondary whitespace-nowrap"
                    style="max-width: 70px"
                    placement="bottom"
                    [ngbTooltip]="
                        (patient$ | async)?.firstName && (patient$ | async).firstName.length >= 7
                            ? (patient$ | async).firstName
                            : ''
                    "
                >
                    {{ (patient$ | async)?.firstName | titlecase }}
                </h6>
            </div>
            <span *ngIf="isChevron" class="material-symbols-outlined solid chevron"> chevron_right </span>
        </div>
        <div>
            <app-bar-card
                [wrapperClass]="isSticky ? 'parentIsSticky' : ''"
                (dateChange)="updateSOCDate($event)"
                [values]="[
                    { title: 'MRN', detail: (facilityAdmission$ | async)?.MRN },
                    { title: 'Admit Date', detail: (facilityAdmission$ | async)?.admitDate | date: 'MM/dd/yyyy' },
                    (type != 'admission') == true
                        ? {
                              title: 'Start of Care',
                              detail: currentTherapyAdmission?.SOCDate | date: 'MM/dd/yyyy',
                              type: selectedNote && selectedNote.toLowerCase() == 'evaluation' ? 'date-picker' : '',
                              minSOCDate: (facilityAdmission$ | async)?.admitDate
                          }
                        : { title: 'Date Of Birth', detail: (patient$ | async)?.DOB | dateFormatter },
                    (type != 'admission') == false
                        ? { title: 'SSN', detail: (patient$ | async)?.SSN }
                        : { title: 'Order Date', detail: currentTherapyAdmission?.orderDate | date: 'MM/dd/yyyy' },
                    {
                        title: 'Discipline',
                        detail: currentDiscipline || activeDiscipline
                    },
                    {
                        title: 'Facility',
                        detail:
                            (facilityAdmission$ | async)?.facility?.facilityName?.length > 12
                                ? (facilityAdmission$ | async)?.facility?.facilityName.slice(0, 12) + '...'
                                : (facilityAdmission$ | async)?.facility?.facilityName
                    },
                    {
                        title: 'Payor',
                        detail:
                            (payorInformation$ | async)?.payor?.name?.length > 12
                                ? (payorInformation$ | async)?.payor?.name.slice(0, 12) + '...'
                                : (payorInformation$ | async)?.payor?.name
                    },
                    documentDueDate
                        ? { title: 'Service Date', detail: documentDueDate | dateFormatter }
                        : { title: 'DueDate', detail: false }
                ]"
            >
            </app-bar-card>
        </div>
    </div>
    <div class="mrn-patient-placeholder" id="mrn-placeholder"></div>
</ng-template>

<ng-template #skeleton>
    <div>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '7em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '70em', height: '7em', 'margin-left': '3em' }"
        ></ngx-skeleton-loader>
        <br />
    </div>
</ng-template>
