import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUserTherapistAssistant } from '@app/helpers';
import { DOCUMENTATION_ROUTES } from '@app/helpers/constants';
import { getCurrentUser } from '@app/helpers/constants/documentation-report';
import { AuthPolicies } from '@app/helpers/utils';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS } from '@app/interfaces/documentation';
import { AuthService, FacilityManagementService } from '@app/services';
import { EvaluationService } from '@app/services/evaluation.service';
import { PatientState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { isUserStudentTherapist } from '../../../../helpers/utils/therapist.role';

@Component({
    selector: 'app-info-bar',
    templateUrl: './info-bar.component.html',
    styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent {
    @Input() documentType: string;
    @Input() isButton = true;
    @Input() statusChip = { show: false, text: '' };
    @Input() disableButtons = false;
    @Input() isCoSign = false;

    therapyDiscipline: any;
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    private readonly onDestroy = new Subject<void>();
    policies = AuthPolicies;
    user = getCurrentUser();
    studentCoSignStatus = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private AuthService: AuthService,
        private evalService: EvaluationService,
        private facilityManagementService: FacilityManagementService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
        }>
    ) {
        this.studentCoSignStatus = this.getStudentCoSignStatus();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
    }

    admissionDischarged = false;
    scrollToElement(id: string) {
        if (this.AuthService.isManager || this.AuthService.isTherapist) {
            const element = document.getElementById(id);
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.AuthService.isManager && !this.AuthService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.disableButtons) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };

    onEdit() {
        const docUrl = DOCUMENTATION_ROUTES.DOCUMENTATION_URL;
        switch (this.documentType) {
            case DOCUMENTATION_CONSTANTS.EVALUATION:
                this.evalService.isPatientAssessmentScreen = false;
                this.router.navigate([docUrl + DOCUMENTATION_ROUTES.EVALUATION_URL], {
                    queryParams: { eval: 'medical-review', edit: true },
                    queryParamsHandling: 'merge',
                });
                break;
            case DOCUMENTATION_CONSTANTS.DAILY:
                this.router.navigate([docUrl + DOCUMENTATION_ROUTES.DAILY_NOTE_URL], {
                    queryParams: { edit: true },
                    queryParamsHandling: 'merge',
                });
                break;
            case DOCUMENTATION_CONSTANTS.PROGRESS:
                this.router.navigate([docUrl + DOCUMENTATION_ROUTES.PROGRESS_NOTE_URL], {
                    queryParams: { edit: true, note: 'Progress-Note' },
                    queryParamsHandling: 'merge',
                });
                break;
            case DOCUMENTATION_CONSTANTS.DISCHARGE:
                this.router.navigate([docUrl + DOCUMENTATION_ROUTES.DISCHARGE_NOTE_URL], {
                    queryParams: { edit: true, note: 'Discharge-Note' },
                    queryParamsHandling: 'merge',
                });
                break;
        }
    }

    private getStudentCoSignStatus(): boolean {
        const currentFacility = localStorage.getItem('current-facility');

        if (!currentFacility) return false;

        try {
            const { coSignSettings } = JSON.parse(currentFacility);

            if (!Array.isArray(coSignSettings)) return false;

            const studentCoSign = coSignSettings.find(
                (setting: any) => setting.text === 'Co-Signature for Student (All notes)'
            );

            return studentCoSign?.isChecked ?? false;
        } catch {
            return false;
        }
    }

    canEdit() {
        // Assistant Therapist cannot edit notes except for daily note.
        // Student Therapist cannot edit notes if not allowed in facility settings.
        const isDailyDocument = this.documentType === DOCUMENTATION_CONSTANTS.DAILY;
        const isTherapistAssistant = isUserTherapistAssistant(this.user);
        const isStudentTherapist = isUserStudentTherapist(this.user?.therapist?.therapistType);

        if (
            (isTherapistAssistant && !isDailyDocument) ||
            (isStudentTherapist && !isDailyDocument && !this.studentCoSignStatus)
        ) {
            return false;
        }
        // TODO: Let user open doc if it is JDOC
        if (
            this.documentType == DOCUMENTATION_CONSTANTS.EVALUATION &&
            !this.facilityManagementService._currentFacility.goLiveEnabled
        )
            return true;
        return this.AuthService.applyPolicy(this.policies.ClinicalDocumentation.Edit) && !this.disableButtons;
    }
}