import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService, ToasterService } from '@app/services';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private AuthService: AuthService, private toasterService: ToasterService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if ([401].includes(err.status)) {
                    // auto logout if 401 or 422 response returned from api
                    this.AuthService.logout();
                } else if ([403].includes(err.status) && this.AuthService.accountValue) {
                    const error = 'Access denied';
                    this.toasterService.show({
                        title: 'Error',
                        body: `You don't have rights to perform this action.`,
                        type: 'error',
                    });

                    const currentUrl = this.router.url.replace('/', '');
                    if (
                        currentUrl !== ROUTER_UTILS.config.base.home &&
                        currentUrl !== ROUTER_UTILS.config.base.dorDashboard &&
                        currentUrl !== ROUTER_UTILS.config.base.therapistDashboard
                    ) {
                        this.router.navigateByUrl(ROUTER_UTILS.config.base.home);
                    }
                    return throwError(error);
                } else if ([409].includes(err.status)) {
                    const error = 'Access denied';
                    this.toasterService.show({
                        title: 'Caution',
                        body: err.status === 409 ? `Note is already completed.` : `Note not found.`,
                        type: 'warning',
                    });

                    const currentUrl = this.router.url.replace('/', '');
                    if (
                        currentUrl !== ROUTER_UTILS.config.base.home &&
                        currentUrl !== ROUTER_UTILS.config.base.dorDashboard &&
                        currentUrl !== ROUTER_UTILS.config.base.therapistDashboard
                    ) {
                        this.router.navigateByUrl(ROUTER_UTILS.config.base.home);
                    }

                    return throwError(error);
                }
                const error = (err && err.error && err.error.message) || err.statusText;
                return throwError(error);
            })
        );
    }
}
