import { Component } from '@angular/core';
// import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

export interface DropdownOption {
    name: string;       
    value: string;      
    disabled: boolean;  
    color: string;      
    bgColor: string;   
}
@Component({
    selector: 'app-status-colored-dropdown-pill',
    templateUrl: './status-colored-dropdown.component.html',
    styleUrls: ['./status-colored-dropdown.component.scss'],
    providers: [{ provide: NgbDropdown }],
})

export class StatusColoredDropdownComponent implements ICellRendererAngularComp {
    public params: any;
    public options: DropdownOption[]  = [];
    public isLocked = false;
    public selectedOption: string;
    public defaultEmptyValueTxt: string;


    agInit(params: ICellRendererParams): void {
        this.params = params;
        const { options = [], isLocked = false, defaultSelected = '' , defaultEmptyValueTxt = ''} = this.params;

        this.options = options;
        this.isLocked = isLocked;
        this.selectedOption = defaultSelected;
        this.defaultEmptyValueTxt = defaultEmptyValueTxt || 'Select'
    }

    refresh(): boolean {
        return false;
    }
    getSelectedOptionColor(): string {
        const selectedOption = this.options.find((opt) => opt.value === this.selectedOption);
        return selectedOption?.color || '#1C3775'; // Default color if no match
    }
    getSelectedOptionBgColor(): string {
        const selectedOption = this.options.find((opt) => opt.value === this.selectedOption);
        return selectedOption?.bgColor || '#fff'; // Default bgcolor if no match
    }
    selectOption(option: DropdownOption): void {
        this.selectedOption = option.value;

        const { data, rowIndex, gridName, colDef } = this.params;
        const headerName = colDef?.headerName;

        // Emit selected action to the parent service or handler
        if (this.params.onOptionSelected) {
            this.params.onOptionSelected(option?.value, data, rowIndex, gridName, headerName);
        }
    }
}
