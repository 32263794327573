<div
    *ngIf="!customSelect"
    [class]="formGroupClass"
    [class.select-error]="hasError"
    [style]="formGroupStyle"
    [ngClass]="{ 'd-flex align-items-center mb-0': labelLeft, '': !small }"
>
    <div class="d-flex justify-content-between">
        <label class="{{ labelClass }} {{ labelFont }} d-block" *ngIf="label" [ngClass]="{ 'mr-2 my-0': labelLeft }"
            >{{ label | translate }} {{ isRequired || required ? '*' : '' }}</label
        >
        <ng-container *ngIf="whiteTooltipEnabled">
            <div
                class="material-icons-outlined d-flex flex-column align-self-center ml-1 mb-1 cursor-pointer"
                [ngbTooltip]="customTooltipMessageTemp"
                [placement]="tooltipPlacement"
                [tooltipClass]="'ngb-tooltip-custom-cont'"
            >
                info
            </div>
        </ng-container>
        <div class="d-flex" *ngIf="selectWithContent">
            <app-switch
                *ngIf="selectWithContent && contentInfo.type === 'switch'"
                label="{{ contentInfo.text }}"
                labelClass="body-small mb-0 align-self-center"
                formGroupClass="d-flex mb-0"
                [rightAlignToggle]="rightAlignToggle"
                [(ngModel)]="contentInfo.selectedOption"
                (change)="contentValueChange()"
            ></app-switch>
            <app-switch
                *ngIf="selectWithContent && labelContentInfo?.type === 'switch'"
                label="{{ labelContentInfo?.text }}"
                labelClass="body-small mb-0 align-self-center"
                formGroupClass="d-flex mb-0"
                [rightAlignToggle]="rightAlignToggle"
                [(ngModel)]="labelContentInfo.selectedOption"
                (change)="contentValueChange2()"
                [disabled]="disabled || teleHealthDisabled"
                [teleHealthDisabled]="teleHealthDisabled"
                [ngbTooltip]="
                    teleHealthDisabled
                        ? 'Only needed for eval only. Mark regular evaluations as telehealth from the daily note'
                        : ''
                "
            ></app-switch>
        </div>
    </div>
    <select
        [class]="'form-control rounded-curve' + backgroundClass + ' ' + inputClass"
        [style]="'overflow: hidden !important; white-space: nowrap; text-overflow: ellipsis' + selectStyle"
        [ngModel]="selectedValue?.length ? selectedValue : value"
        [disabled]="disabled"
        [class.disabled-background-color]="disabled"
        (change)="optionSelect($event)"
        [ngClass]="[backgroundClass, foregroundClass]"
        [class.placeholder-select]="value == ''"
        [class.sm]="small"
    >
        <option id="{{ notDisabled }}" class="options" value="" [attr.disabled]="isPlaceholderDisabled ? true : null">
            {{ placeholder }}
        </option>
        <option
            *ngFor="let option of selectOptions"
            class="options"
            [ngClass]="{
                'disable-bg': option.isDisabled || disabledOptions.includes(option),
                backgroundClass: backgroundClass
            }"
            value="{{ option.id || option.name || option | translate }}"
            [disabled]="option.isDisabled || disabledOptions.includes(option)"
            [selected]="option === selected"
        >
            {{ option.name || option | translate }}
        </option>
    </select>
</div>

<div
    [class.select-error]="hasError"
    *ngIf="customSelect"
    [ngClass]="{ 'd-flex align-items-center mb-0': labelLeft, '': !small }"
>
    <label class="d-block">{{ label | translate }} {{ isRequired ? '*' : '' }}</label>
    <div
        ngbDropdown
        class="d-inline-block w-100 border-radius-10 bg-body-background custom-dropdown"
        style="font-family: 'Poppins'; border: 1px solid rgba(28, 55, 117, 0.15)"
        [class.is-required]="isRequired"
        [class.sm]="small"
    >
        <span
            ngbDropdownToggle
            class="d-flex dropdown-toggle justify-content-between align-items-center"
            id="facilityDropdown"
            style="font-weight: 500; padding: 0.5rem 2px 0.5rem 1rem; line-height: 39px"
        >
            <span
                style="
                    width: calc(100% - 10px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: context-menu;
                    font-weight: 400;
                "
                [ngStyle]="{ color: selected || value ? '#495057' : '#b3b3b0' }"
            >
                {{ value || selected || placeholder }}
            </span>
            <span class="material-symbols-outlined align-middle"> expand_more </span>
        </span>
        <div
            class="facility-list-holder w-100 py-0"
            ngbDropdownMenu
            style="border-radius: 8px"
            aria-labelledby="selectDropdown"
        >
            <div class="facility-list-dropdown">
                <ul>
                    <!-- <li *ngIf="placeholder"  (click)="optionSelect(placeholder)" ngbDropdownItem>
                        {{ placeholder }}
                    </li> -->
                    <li
                        *ngFor="let option of selectOptions"
                        (click)="optionSelect(option)"
                        ngbDropdownItem
                        [ngbTooltip]="option.length > 22 ? option.name || option : ''"
                    >
                        {{ option.name || option | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<ng-template #customTooltipMessageTemp>
    <div class="custom-tooltip-wrapper">
        <span class="text-secondary error-info">{{ errorMsg }}</span>
    </div>
</ng-template>
