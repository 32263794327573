/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, ChangeDetectorRef, OnChanges, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-scheduler-time-block',
  templateUrl: './scheduler-time-block.component.html',
  styleUrls: ['./scheduler-time-block.component.scss'],
})
export class SchedulerTimeBlockComponent implements OnChanges {
  @Input() timeBlockIcon: boolean;
  @Input() commentIcon: boolean;
  @Input() description: string;
  @Input() minutes: number;
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() editTimeBlock = true;
  @Input() caseloadDate: string;
  @Input() isSelected = false;
  @Input() placement = 'left';
  @Output() onDeleteTime: EventEmitter<any> = new EventEmitter();
  @Output() onEditTime: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteNote: EventEmitter<any> = new EventEmitter();
  @Output() onEditNote: EventEmitter<any> = new EventEmitter();


  heading: string;
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnChanges(): void {
    this.changeDetector.detectChanges();
    if (this.timeBlockIcon) {
      this.heading = this.formatMinutesToHoursAndMinutes(this.minutes) + ' Time Block';
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  /**
   * Function to format block time minutes into hours and minutes (string)
   * @param minutes
   * @returns String as 1h 15min
   */
  formatMinutesToHoursAndMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes}min`;
    }

    return result;
  }

  /**
   * Function to check if Tooltip should be visible or not
   * @param el - Current element
   * @returns - True if Tooltip text show be visible
   */
  isOverflow(el: HTMLElement): boolean {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
    const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  }

  deleteBlock() {
    if(this.timeBlockIcon) this.onDeleteTime.emit();
    if(this.commentIcon) this.onDeleteNote.emit();
  }

  editBlock() {
    if(this.timeBlockIcon) this.onEditTime.emit();
    if(this.commentIcon) this.onEditNote.emit();
  }
}
