<div class="{{ positionRelative }}" [ngClass]="{ 'd-flex align-items-center mb-0': labelLeft }">
    <div
        id="{{ positionAbsolute }}"
        class="form-group d-flex justify-content-between mb-0"
        [class.input-error]="hasError"
    >
        <label class="{{ labelClass }} {{ marginBottom }} {{ marginZero }}"
            >{{ label | translate }} {{ (isRequired && !hideLabel) || (required && !hideLabel) ? '*' : '' }}</label
        >
        <ng-container *ngIf="!whiteTooltipEnabled; else customTooltipTemplate;">
            <div
                *ngIf="(hasError && !hideLabel) || showErrorTooltip"
                ngbTooltip="{{ errorMsg }}"
                placement="{{ tooltipPlacement }}"
                class="material-icons-outlined d-flex flex-column align-self-center ml-1 text-danger mb-1 cursor-pointer"
            >
                info
            </div>
        </ng-container>
    </div>

    <div
        class="form-group rounded-curve d-flex {{ marginTop }} {{marginBottom}}"
        [class.input-error]="hasError"
        [class.input-group]="inputWithContent"
    >
        <div *ngIf="preText?.text" class="pre-text-box">
            <span [ngStyle]="preText?.style" class="text-secondary">{{preText.text}}</span>
        </div>
        <div *ngIf="preTextSvg" class="pre-text-box">
            <img src="{{preTextSvg}}" />
        </div>
        <input
            #inputField
            class="form-control {{ inputClass }}"
            [ngClass]="backgroundClass ? backgroundClass : ''"
            [ngClass]="{
                'input-with-content pr-0': inputWithContent,
                error: hasError,
                'disabled-background-color': disabled,
                'pl-4': preTextSvg
            }"
            [type]="type"
            [placeholder]="placeholder"
            [value]="value"
            [disabled]="disabled"
            (keyup)="onChange($event)"
            (change)="onChange($event)"
            (blur)="onBlur()"
            [imask]="maskObj"
            autocomplete="{{ autocomplete }}"
            minlength="minLength"
            maxlength="maxLength"
            [readonly]="readOnly ? 'readonly' : ''"
        />
        <div *ngIf="postText?.text" class="post-text-box">
            <span [ngStyle]="postText?.style" class="text-secondary" [ngClass]="{'text-disabled':disabled}">{{postText.text}}</span>
        </div>
        <div *ngIf="inputWithContent" class="input-group-append" [ngClass]="{ 'disabled': disabled }">
            <select
                *ngIf="contentInfo.type === 'dropdown'"
                [ngClass]="{ 'span-content-error': hasError }"
                [(ngModel)]="temperatureScale"
                (change)="emitChangeWithContent(); emitChangeWithContentSelectedOption()"
                [disabled]="disabled"
            >
                <ng-container
                    *ngIf="contentInfo.options?.includes('C') && contentInfo.options?.includes('F'); else default"
                >
                    <option value="C">&#176;C</option>
                    <option value="F">&#176;F</option>
                </ng-container>
                <ng-template #default>
                    <option *ngFor="let option of contentInfo.options" [value]="option">{{ option }}</option>
                </ng-template>
            </select>
            <span
                class="input-group-text body-medium text-secondary pl-1"
                [ngClass]="{ 'span-content-error': hasError }"
                *ngIf="contentInfo.type === 'text'"
                >{{ contentInfo.text }}</span
            >
            <button
                *ngIf="contentInfo.type === 'button'"
                type="button"
                class="btn p-0 d-flex align-items-center justify-content-center {{ contentClass }}"
                (click)="emitChangeWithContent(contentInfo.text); emitChangeWithContentSelectedOption()"
                [disabled]="disabled"
            >
                <span class="symbol material-symbols-outlined mr-1">{{ contentInfo.icon }}</span>
                {{ contentInfo.text }}
            </button>
        </div>
    </div>
</div>

<ng-template #customTooltipTemplate>
    <div
        *ngIf="(hasError && !hideLabel) || showErrorTooltip"
        class="material-icons-outlined d-flex flex-column align-self-center ml-1 text-danger mb-1 cursor-pointer"
        [ngbTooltip]="customTooltipMessageTemp" [placement]="tooltipPlacement"
        [tooltipClass]="'ngb-tooltip-custom-cont'"
    >info</div>
</ng-template>

<ng-template #customTooltipMessageTemp>
    <div class="custom-tooltip-wrapper">
        <span class="text-danger error-info">{{whiteTooltipWarning}}</span>&nbsp;
        <span class="text-secondary error-info">{{errorMsg}}</span>
    </div>
</ng-template>