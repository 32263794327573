<div ngbDropdown *ngIf="params" class="d-inline-block" [container]="'body'">
    <!-- select_custom_box -->
    <div *ngIf="isLocked" class="select_custom_box" loading="lazy" 
        >
        <span>{{ selectedOption }}</span>
    </div>

    <div *ngIf="!isLocked" class="select_custom_box"  loading="lazy"
        [style.color]="!selectedOption ? '#A5B5D9': getSelectedOptionColor()" 
        [style.backgroundColor]="getSelectedOptionBgColor()"[ngClass]="{'custom-arrow-color': !selectedOption}"
         ngbDropdownToggle>
        <span>{{ selectedOption || defaultEmptyValueTxt}}</span>
    </div>

    <!-- dropdown options -->

    <div ngbDropdownMenu class="custom-dropdown-show"  aria-labelledby="dropdownBasic1">
        <ng-container *ngFor="let option of options">
            <button class="body-small" ngbDropdownItem [disabled]="selectedOption === option.value || option.disabled"
                 (click)="selectOption(option)"
                [ngStyle]="{
                    color:
                        selectedOption === option.value || option.disabled
                            ? '#D1D1D0'
                            :  option.color || '#1C3775'
                }">
                {{ option.name }}
            </button>
        </ng-container>
    </div>
</div>