import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Account, FacilityAdmission, User } from '@app/models';
import { Role } from '@app/helpers';
import { Store } from '@ngrx/store';
import {
    userChangeAction,
    patientRemovedAction,
    scheduleResetAction,
    tabsResetAction,
    userLogoutAction,
    facilityAdmissionRemovedAction,
} from '@app/store/index';
import { PatientState } from '@app/store/store.interface';
import { StoreService } from './store.service';
import { TimeTrackingService } from './timeTracking.service';
import { ModalService } from './modal.service';
import { datadogRum } from '@datadog/browser-rum';

const baseUrl = `${environment.apiUrl}/auth`;

@Injectable({ providedIn: 'root' })
export class AuthService {
    public accountSubject: BehaviorSubject<Account>;
    public roleSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public account: Observable<Account>;
    public logout$ = new Subject<boolean>();

    private inactivityTime = 0;
    private readonly maxInactivityTime: number = 10 * 60;
    private stopTimer = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private store: Store<{
            user: any;
            accessToken: any;
            refreshToken: any;
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
        }>,
        private storeService: StoreService,
        private timeTrackingService: TimeTrackingService,
        private modalService: ModalService
    ) {
        this.startTimer();
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
        window.addEventListener('storage', (event) => {
            if (event.key === 'logoutEvent' && this.accountSubject.value) {
                this.logout();
            }
        });
    }
    onActivity() {
        this.inactivityTime = 0;
    }
    startTimer() {
        setInterval(() => {
            if (!this.stopTimer) {
                this.inactivityTime++;
                if (this.inactivityTime >= this.maxInactivityTime) {
                    if (this.accountValue) {
                        this.stopTimer = true;
                        this.modalService.closeAll();
                        this.removeCustomRootTooltip();
                        this.logout();
                    }
                }
            }
        }, 1000);
    }
    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    public get rolesValue(): any[] {
        return this.roleSubject.value;
    }

    removeCustomRootTooltip() {
        const rootTooltip: HTMLCollection = document.getElementsByClassName('custom-root-tooltip');
        for (let index = 0; index < rootTooltip.length; index++) {
            rootTooltip[index].classList.add('d-none');
        }
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${baseUrl}/login`, { email, password }, { withCredentials: true }).pipe(
            map((account) => {
                const decodedJwt = this.decodeJwt(account.data.accessToken.token);
                account.data.user.rightPolicies = decodedJwt.rights;
                this.accountSubject.next(account.data);
                localStorage.removeItem('time-tracking');
                localStorage.removeItem('timeDialog');
                localStorage.removeItem('innova-frontend');
                localStorage.removeItem('current-facility');
                localStorage.removeItem('current-organization');
                this.storeTokens(account.data);
                this.stopTimer = false;
                datadogRum.setUser({
                    id: account.data.user.id,
                    name: account.data.user.firstName + ' ' + account.data.user.lastName,
                    email: account.data.user.email,
                    organization: account.data.user.organization,
                    facility: account.data.user.facility,
                });
                return account.data;
            })
        );
    }
    decodeJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
    }
    logout(redirect = true) {
        const isPhysician = this.isPhysician; // colworx-sk
        const isPhysicianStaff = this.isPhysicianStaff; // colworx-sk
        window.stop();
        this.http.post<any>(`${baseUrl}/logout`, {}, { withCredentials: true }).subscribe(() => {
            localStorage.setItem('logoutEvent', Date.now().toString());
            this.stopRefreshTokenTimer();
            this.accountSubject.next(null);
            this.roleSubject.next([]);
            this.deleteToken();
            this.resetState();
            this.logout$.next(true);
            if (redirect) {
                // colworx-sk
                if (isPhysician || isPhysicianStaff) {
                    this.router.navigate(['/account/physician/login']);
                } else {
                    this.router.navigate(['/account/login']);
                }
            }
        });
    }
    logoutObs(): Observable<boolean> {
        return this.http.post<any>(`${baseUrl}/logout`, {}, { withCredentials: true })
        .pipe(
            switchMap(() => {
                localStorage.setItem('logoutEvent', Date.now().toString());
                this.stopRefreshTokenTimer();
                this.accountSubject.next(null);
                this.roleSubject.next([]);
                this.deleteToken();
                this.resetState();
                this.logout$.next(true);
                return of(true);
            }),
            catchError((err: HttpErrorResponse) => {
                console.log("logoutObs ==>", err.message);
                return of(false)
            })
        );
    }
    resetState() {
        this.store.dispatch(userLogoutAction());
        this.store.dispatch(patientRemovedAction());
        this.store.dispatch(facilityAdmissionRemovedAction());
        this.store.dispatch(scheduleResetAction());
        this.store.dispatch(tabsResetAction());
        this.storeService.resetPlannerTherapyAdmissions();
        this.storeService.setLoadingState(false);
    }

    refreshToken() {
        const data: Account = JSON.parse(localStorage.getItem('innova-frontend'));
        if (data) {
            return new Observable((observer) => {
                this.http
                    .post<any>(`${baseUrl}/token`, {
                        accessToken: data?.accessToken.token,
                        refreshToken: data?.refreshToken.token,
                    })
                    .pipe(
                        map((account) => {
                            account.data.user = data.user;
                            account = account.data;
                            datadogRum.setUser({
                                id: account.user.id,
                                name: account.user.firstName + ' ' + account.user.lastName,
                                email: account.user.email,
                                organization: account.user.organization,
                                facility: account.user.facility,
                            });
                            this.accountSubject.next(account);
                            this.storeTokens(account);
                            return account;
                        })
                    )
                    .subscribe({
                        next: (response) => {
                            const account = response;
                            account.user = data.user;
                            this.accountSubject.next(account);
                            this.storeTokens(account);
                            observer.next(account);
                            observer.complete();
                        },
                        error: (error) => {
                            observer.error(error);
                        },
                    });
            });
        } else {
            return of(null);
        }
    }
    updateAccount(account:any){
        this.accountSubject.next(account)
        this.storeTokens(account)
    }
    updateToken(user: User) {
        const updatedAccountInfo = { ...this.accountValue, user };
        this.storeTokens(updatedAccountInfo);
    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }
    // colworx-ak: Add validateUserEmail to check if this user email exists in the database
    validateUserEmail(email: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/validate-user`, { email }, { withCredentials: true }).pipe(
            map((data) => {
                return data;
            })
        );
    }
    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/password-reset`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<Account[]>(baseUrl);
    }

    create(params) {
        return this.http.post(`${baseUrl}/register`, params);
    }

    submitFeedback(params) {
        return this.http.post(`${baseUrl}/slack-feedback`, params);
    }
    createFreshDeskTicket(body) {
        return this.http.post(`${baseUrl}/create-fresh-desk-ticket`, body);
    }

    // clear all session, local and cookie storage
    wipeAllStorage(): Observable<boolean> {
        return this.logoutObs().pipe(tap(() => {
            localStorage.removeItem('current-organization');
            localStorage.removeItem('logoutEvent');
            sessionStorage.clear();
        }));
    }
    // helper methods

    private storeTokens(account: Account) {
        // delete account.accessToken.expires;
        // delete account.refreshToken.expires;
        localStorage.setItem('innova-frontend', JSON.stringify(account));
        this.store.dispatch(userChangeAction(account));
    }

    private deleteToken() {
        // delete account.accessToken.expires;
        // delete account.refreshToken.expires;
        localStorage.removeItem('innova-frontend');
        localStorage.removeItem('current-facility');
        localStorage.removeItem('current-organization');
        // this.store.removeReducer('user');
    }
    private refreshTokenTimeout;

    // private startRefreshTokenTimer() {
    //   // parse json object from base64 encoded jwt token
    //   const jwtToken = JSON.parse(atob(this.accountValue.accessToken.token.split('.')[1]));

    //   // set a timeout to refresh the token a minute before it expires
    //   const expires = new Date(this.accountValue.refreshToken.expires * 1000);
    //   const timeout = expires.getTime() - Date.now() - 60 * 1000;
    //   this.refreshTokenTimeout = setTimeout(() => this.accountValue.refreshToken.token, timeout);
    // }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
    public get isSystemAdmin(): boolean {
        return this.rolesValue.map((role) => role.baseRole).includes(Role.SystemAdmin);
    }
    public get isTherapist(): boolean {
        return this.rolesValue.map((role) => role.baseRole).includes(Role.Therapist);
    }
    public get isPhysician(): boolean {
        return this.rolesValue.map((role) => role.baseRole).includes(Role.Physician);
    }
    public get isManager(): boolean {
        return this.rolesValue.map((role) => role.baseRole).includes(Role.Manager);
    }
    // colworx-ak define isPhysicianStaff for compare from baseRole
    public get isPhysicianStaff(): boolean {
        return this.rolesValue.map((role) => role.baseRole).includes(Role.PhysicianStaff);
    }
    public get onlyPhysicianORPhysicianStaff(): boolean {
        return (
            !this.isSuperAdmin &&
            !this.rolesValue.find((role) => ![Role.PhysicianStaff, Role.Physician].includes(role.baseRole))
        );
    }
    public get isSuperAdmin(): boolean {
        const { user } = this.accountSubject.value || {};
        if (!user || (user && !user.roles) || (user.roles && !user.roles.length)) {
            return false;
        }
        const role = user.roles.find((role) => role.baseRole === Role.SuperAdmin);
        return !!role; // is a super admin
    }
    public get isSuperAdminOnly(): boolean {
        return this.isSuperAdmin && !this.rolesValue?.length;
    }

    applyPolicy(policy: string): boolean {
        if (!policy) {
            return false;
        }
        const rightPoliciesArray = this.roleSubject.value.map((role) => role.rights).flat();
        return (
            (rightPoliciesArray &&
                !!rightPoliciesArray.find(
                    (policyItem) => policyItem?.value?.toLowerCase() === policy?.toLowerCase()
                )) ||
            this.isSuperAdmin
        );
    }

    checkPermission(resource: any) {
        return this.applyPolicy(resource['Add']);
    }

    checkMode(resource: any) {
        let mode = '';
        mode = this.applyPolicy(resource['Add'])
            ? 'create'
            : this.applyPolicy(resource['Edit'])
                ? 'update'
                : this.applyPolicy(resource['View'])
                    ? 'view'
                    : '';

        return mode;
    }
}
