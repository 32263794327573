export function getFacilityCoSignStatus(): boolean {
    const currentFacility = localStorage.getItem('current-facility');

    if (!currentFacility) return false;

    try {
        const { coSignSettings } = JSON.parse(currentFacility);

        if (!Array.isArray(coSignSettings)) return false;

        const studentCoSign = coSignSettings.find(
            (setting: any) => setting.text === 'Co-Signature for Student (All notes)'
        );

        return studentCoSign?.isChecked ?? false;
    } catch {
        return false;
    }
}