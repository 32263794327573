import { environment } from './../../environments/environment';
// colworx-ak start
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { ROUTER_UTILS } from '../helpers/utils';
import { ToasterService } from './toaster.service';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/document-detail`;
@Injectable({
    providedIn: 'root',
})
export class PpDocumentsService {


    constructor(private toastService: ToasterService, private http: HttpClient) { }


    private dataSource = new BehaviorSubject<any>(this.getDataFromStorage());

    currentData = this.dataSource.asObservable();

    updateData(data: any) {
        this.dataSource.next(data);
        localStorage.setItem('patientData', JSON.stringify(data));
    }

    formatToTitleCase(name: string): string {
        const formattedName = name.trim();
        return formattedName
            ? formattedName
                .split(' ')
                .filter(word => word) // Remove empty strings caused by multiple spaces
                .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
            : 'N/A';

    }

    somethingWentWrong() {
        this.toastService.show({
            title: 'Error',
            body: `Something Went Wrong`,
            type: 'error',
        });
    }

    getPocListHeaders(): ColDef[] {
        return [
            {
                headerName: 'Patients',
                field: 'patient',
                valueGetter: (v) => {
                    return this.formatToTitleCase(v?.data?.patientName || '');
                },
                sortable: true,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                resizable: true,
                sortingOrder: ['asc', 'desc'],
                colId: 'patientName',
            },
            {
                headerName: 'Document Type',
                valueGetter: (v) => `${v?.data?.documentType}`,
                resizable: true,
            },
            {
                headerName: 'Physician',
                field: 'physician',
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                cellRenderer: (params) => {
                    const { physician_is_active: isActive, physicianName } = params?.data || {};
                    const style = !isActive ? 'text-gray-400' : 'text-secondary';
                    const name = this.formatToTitleCase(physicianName || 'N/A');
                    return `<span class='${style}'>${name}</span>`;
                },
                resizable: true,
                colId: 'physicianName',
            },
        ];
    }

    getSubUrl(event) {
        return event.documentType == 'Daily Note'
            ? ROUTER_UTILS.config.documentationViewMode.dailyNoteDoc.root
            : event.documentType == 'Progress Note'
                ? ROUTER_UTILS.config.documentationViewMode.progressNoteDoc.root
                : event.documentType == 'Updated Plan of Care'
                    ? ROUTER_UTILS.config.documentationViewMode.updatePlanOfCare.root
                    : event.documentType == 'Recertification'
                        ? ROUTER_UTILS.config.documentationViewMode.reCertificationDoc.root
                        : event.documentType == 'Discharge Note'
                            ? ROUTER_UTILS.config.documentationViewMode.dischargeNote.root
                            : event.documentType == 'Evaluation'
                                ? ROUTER_UTILS.config.documentationViewMode.evalDoc.root
                                : event.documentType == 'Addendum'
                                    ? ROUTER_UTILS.config.documentationViewMode.addendum.root
                                    : null;
    }

    sortDocuments(docData: any[], sortField: string, sortOrder: string): any[] {
        return docData?.sort((a, b) => {
            let fieldA = a[sortField];
            let fieldB = b[sortField];

            if (sortField === 'dueDate') {
                // Handle date sorting
                fieldA = new Date(a.dueDate).getTime();
                fieldB = new Date(b.dueDate).getTime();
            } else if (sortField === 'patientName') {
                // Handle sorting for patient name (case-insensitive)
                fieldA = a.patientName ? a.patientName.toLowerCase() : '';
                fieldB = b.patientName ? b.patientName.toLowerCase() : '';
            } else if (sortField === 'physicianName') {
                // Handle sorting for physician names (case-insensitive)
                fieldA = a.physicianName ? a.physicianName.toLowerCase() : 'N/A';
                fieldB = b.physicianName ? b.physicianName.toLowerCase() : 'N/A';
            }

            // Sorting logic (asc or desc)
            if (sortOrder === 'asc') {
                return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            } else {
                return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
            }
        });
    }

    sortData(event: any, accounts: any[]): { sortField: string; sortOrder: string; sortedData: any[] } {
        const sort = event.columnApi.getColumnState().filter((column) => column.sort !== null);
        let sortField = '';
        let sortOrder = '';

        if (sort.length) {
            sortField = sort[0].colId;
            sortOrder = sort[0].sort;
        }

        // Sort the existing data instead of hitting the API
        const sortedData = this.sortDocuments(accounts, sortField, sortOrder);
        return { sortField, sortOrder, sortedData };
    }
    private getDataFromStorage() {
        const data = localStorage.getItem('patientData');
        return data ? JSON.parse(data) : null;
    }

    updatePocByDor_therapist(obj: any): Observable<any> {
        return this.http.put(`${baseUrl}/update-poc-by-therapist`, obj);
    }
    resetPocByDor_therapist(obj: any): Observable<any> {
        return this.http.put(`${baseUrl}/reset-poc`, obj);
    }


}
