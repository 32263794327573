import { Physician } from "@app/interfaces";

export class User {
    id: string;
    therapist?: Therapist;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    policy: string;
    roles: any[];
    physician?: Physician;
    physicianStaff: any;
    rightPolicies: string[];
    facility: string;
    selectedFacility: string;
    organization: string;
    createdAt?: Date;
    employeeId?: string;
    userRoles?:  any[];
    activityRates?:[]
    _id?: string;
}

export interface Therapist {
    id: string;
    discipline: string;
    therapistType: string;
    licensures: Licensure[];
    signCredentials: string;
}

export interface Licensure {
    licensureNumber : string;
    state : string;
    expirationDate : string;
}

export interface ActivateOrDeactivate {
    id: string;
    isActive: boolean;
}

export enum USER_TYPES {
    PHYSICIAN = 'PHYSICIAN',
    THERAPIST = 'THERAPIST',
    NONE = 'NONE',
    OTHER = 'OTHER'
}

export interface IActivityRate {
    facilities: string[]
    laborTime: number
    administrativeTime: number
    travelTime: number
}
