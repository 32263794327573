import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { FormControl } from '@angular/forms';
import { parseISO } from 'date-fns';
import { setHours } from '@app/helpers/constants';
@Component({
    selector: 'app-date-cell-renderer',
    templateUrl: './date-cell-renderer.component.html',
    styleUrls: ['./date-cell-renderer.component.scss'],
})
export class DateCellRendererComponent  {
    dateStringControl = new FormControl();
    params : any;
    valueType: 'value' | 'valueAsDate' = 'value';

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
        if (params.data.endDate) {
            const isoDateString = params.data.endDate;
            const date = new Date(isoDateString);
            const formattedDate = date.toISOString().split('T')[0];
            this.dateStringControl = new FormControl(formattedDate);
        } else {
            this.dateStringControl.setValue('');
        }
    }

    getInputClasses() {
        return {
            [this.params.calendarClass]: true,
            'disable': !this.params.editMode,
            'hide-borders': this.params.disabled,
            'not-enabled': !this.params.data.enable


        };
    }


    writeValue(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const selectedDate = inputElement.value;
        const selectedValue = inputElement.value.trim();
        const year = selectedValue.split('-')[0];
        if(year.length > 4){
            this.dateStringControl.setValue('');
            this.params.inputChange(null)
            return;
        }
        const formattedDate = parseISO(selectedDate);
        const updatedDate = setHours(formattedDate)
        this.params.inputChange(updatedDate);
    }

}
